.root-header {
    padding: 12px 0;
    position: sticky;
    top: 0;
    z-index: 1026;
    background: #fff;
    width: 100%;
    border-bottom: 1px solid #EAEAEA;

    .alert {
        padding-bottom: 10px;
        padding-top: 12px;
    }

    & .btn.root-header__button {
        border-radius: 8px;
        padding: 9px;
        background-color: transparent;

        &.dropdown__link.dropdown-visible > span > span {
            color: rgba(26, 50, 186, 1);
        }


        &--with-hover-animation {
            transition: background-color .3s ease-in-out;
            &:hover {
                background-color: var(--additional-blue-4000);
            }
        }

        &--bordered {
            border: 1px solid var(--additional-blue-4000);
        }

        &--padding-2 {
            padding: 1px;
        }

        &--active {
            background-color: var(--additional-blue-4000);
        }
    }

    &__button-icon-with-count {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap:5px;

    }
    &__button-icon-with-count-favorite {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap:2px;

    }

    &__button-icon-with-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &__button-counter  {
        background-color: #eb5757;
        border: 1px solid #fff;
        border-radius: 32px;
        color: #fff;
        font-size: 12px;
        left: 48%;
        min-height: 16px;
        min-width: 16px;
        padding-inline: 3px;
        position: absolute;
        top: 10%;
        transform: translateY(-50%);
        line-height: 1.25;
    }

    &__button-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        transition: color .3s ease-in-out;

        &:hover{
            cursor:pointer;
            .root-header__button-text{
                color: var(--blue-dark)
            }
        }

    }

    &__button-text {
        font-size: 12px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--black-4000);

        &--white-space-normal {
            white-space: normal;
        }

        &--with-icon {
            display: inline-flex;
            gap: 3px;
            align-items: center;

            & svg {
                transition: transform .3s ease-in-out;
            }
        }
    }
    &__button-text-personal {
        font-size: 12px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--black-4000);

        &--white-space-normal {
            white-space: normal;
        }

        &--with-icon {
            display: inline-flex;
            gap: 3px;
            align-items: center;

            & svg {
                transition: transform .3s ease-in-out;
            }
        }
    }


    &__buttons-panel {
        margin-left: auto;
        display: flex;
        align-items:center;

        & > .root-header__button-container {
            display: flex;

            &:not(:last-child)::after {
                content: '';
                display: inline-block;
                width: 1px;
                background-color: var(--additional-blue-4000);
                margin: 0 12px;
                align-self: center;
                height: 40px;
                transform: scaleX(0.5);
            }
        }
    }

    &__logo-link {
        font-size: 0;
        @include media("<=1340px"){
            svg {
                display:none !important;
            }
        }
    }


    &__wrapper {
        display: flex;
        align-items: center;

        @media (max-width: 1268px) {
            gap: 40px;
        }

        @media (min-width: 1268px) {
            gap: 0;

        }

    }

    &__search {
        position: relative;
        width:100%;

        @media (min-width: 1268px) {
           margin-left:100px;
            margin-right:30px;

        }

         & input.header-search__input.textinput.textInput.form-control {
            background-color: #F7F7F7 !important;
            border-radius: 9px !important;
            border: none;
           background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.582 14.5833L17.4987 17.4999' stroke='%23D671B5' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16.668 9.16675C16.668 13.3089 13.3101 16.6667 9.16797 16.6667C5.02583 16.6667 1.66797 13.3089 1.66797 9.16675C1.66797 5.02461 5.02583 1.66675 9.16797 1.66675C13.3101 1.66675 16.668 5.02461 16.668 9.16675Z' stroke='%231A32BA' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat calc(100% - 12px) center;
             min-width: 260px;
             margin: 0 auto;
            padding-right:35px !important;
             color:var(--black-1000);
            &::placeholder {
                font-size: 13px;
                color: rgba(0, 0, 0, 0.6);
            }
         }
    }

    &__search-dropdown {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__dropdown-container {
        height: 100%;
        overflow-y: auto;
        padding-right: 5px;

        & ul li {
            padding: 4px;
            cursor: pointer;
            border-radius: 8px;
        }

        &::-webkit-scrollbar {
            width: 4px;
            background-color: var(--mantine-color-gray-0);
          }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: #868E96;
        }

        &::-webkit-scrollbar-track {
            border-radius: 8px;
            background-color: #fff;
        }
    }
}

.header-search {
    & .form-group {
        margin: 0 !important;
    }
}

.top-header {
    margin: 0;
    flex-flow: row wrap;
    padding: 8px 15px;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--header-controls-font-size);
    font-weight: var(--header-controls-font-weight);

    span {
      font-size: var(--header-controls-font-size) !important;
      font-weight: var(--header-controls-font-weight) !important;
      overflow-wrap: break-word;
    }

    @include media(">=sm") {
        justify-content: flex-end;
        padding: 0 20px;
    }

    @include media(">=xl") {
        padding: 0 10px;
    }

    &__dropdown {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 20px;
        &-body {
            &:before {
                left: 15px;
                @include media(">=sm") {
                    left: 20px;
                }
                @include media(">=xl") {
                    left: 10px;
                }
            }
            &.right {
                left: auto;
                right: -15px;
                @include media(">=sm") {
                    right: 0px;
                }
                @include media(">=xl") {
                    right: -10px;
                }
                &:before {
                    left: auto;
                    right: 15px;
                    @include media(">=sm") {
                        right: 20px;
                    }
                    @include media(">=xl") {
                        right: 10px;
                    }
                }
                &-xl {
                    @include media(">=xl") {
                        right: -10px;
                    }
                    &:before {
                        @include media(">=xl") {
                            right: 10px;
                        }
                    }
                }
            }
        }

        &_mr {
            margin-right: 13px;
            @include media(">=sm") {
                margin-right: 21px;
            }
        }
    }

    &__section {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 11px;
        margin-bottom: 11px;

        &_wiki {
            flex: 1;
        }
    }

    &__link {
        cursor: pointer;
        font-style: normal;
        font-weight: var(--header-controls-font-weight);
        font-size: var(--header-controls-font-size);
        line-height: 15px;
        color: #4b4b4b;
        &:hover {
            text-decoration: none;
            color: #4b4b4b;
        }
    }
}

.top-header__dropdown span {
    font-size: var(--header-controls-font-size);
    font-weight: var(--header-controls-font-weight);
}
.top-header__dropdown:not(:last-child) {
    margin-right: 20px;
}

.top-header-phone-block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 20px;
    font-size: var(--header-controls-font-size);
    font-weight: var(--header-controls-font-weight);
    > a {
        margin-left: 5px;
        text-decoration: none!important;
        border-bottom: none;
        color: #4b4b4b;
    }
}

.main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    z-index: 300;
    background-color: inherit;
    @include media(">=xl") {
        padding-right: 10px;
    }

    &__section {
        &_auto {
            position: relative;
        }
        &_buttons {
            justify-content: space-between;
            .main-header__btn {
                width: 64px;
                height: 64px;
            }
        }
        &-btn {
            flex: 1 1 0;
            &:last-child {
                .bordered {
                    border: 0;
                }
            }
        }

        @include media("<=1440px"){
            .main-header__nav-btn{
                display: none !important;
            }
        }
        @include media("<=1339px"){
            .main-header__nav-btn {
                display: block !important;
                padding: 20px 0 0 10px;
            }
        }
    }

    &__btn {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.bordered {
            border-right: 1px solid #E5E5E5;
        }

        @include media(">=sm") {
            width: auto;
            padding-left: 0;
            padding-right: 0;
        }

        &-text {
            margin-left: 6px;
            font-style: normal;
            font-weight: var(--header-controls-search-font-weight);
            font-size: var(--header-controls-search-font-size);
            line-height: 15px;
            color: #4B4B4B;
            &:hover, &:active {
                color: #4B4B4B;
            }
        }
    }

    &__nav {
        &-btn {
            padding: 0;
            width: 64px;
        }
    }

    &__logo {
        &-link {
            display: flex;
        }
        &-img {
            display: block;
            width: auto;
            max-width: 100%;
            max-height: 50px;
        }
    }

    &__profile {
        margin-right: 22px;
    }

    &__company {
        margin-right: 22px;
    }

    &__hidden-container {
        width: 100%;
        height: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__actions {
        display: flex;
    }
}


.search-trigger {
    &__btn {
        &-text {
            font-style: normal;
            font-weight: var(--header-controls-search-font-weight);
            font-size: var(--header-controls-search-font-size);
            line-height: 21px;
            color: #666;

        }
        &-icon {
            height: 24px;
        }
    }
}

.cart-trigger {
    &__icon {
        fill: #979797;
        @include media(">=xl") {
            fill: #fff;
        }
    }
    &__btn {
        @include media(">=xl") {
            background: #4D83D6;
            border-radius: 8px;
            height: 43px;
            padding: 10px 16px;
            margin-left: 10px;
        }

        &-text {
            font-style: normal;
            line-height: 14px;
            color: #292929;
            span {
              font-weight: var(--header-controls-search-font-weight) !important;
              font-size: var(--header-controls-search-font-size) !important;
            }
            &:hover, &:active {
                color: #292929;
            }

            @include media(">=xl") {
                color: #fff;
                &:hover, &:active {
                    color: #fff;
                }
            }
        }
    }
}

.header-search {
    background: #fff;

    &_wide {
        padding-right: 0;
        border-right: 0;
        position: absolute;
        left: 0;
        right: 0;

        .header-search {
            &__extended {
                width: 64px;
                height: 64px;
                justify-content: center;
                border-left: 1px solid #E5E5E5;
                &-text, &-icon {
                    display: none;
                }
            }
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        position: relative;
    }

    &__dropdown {
        display: none;
        position: absolute;
        top: calc(100% + 5px);
        border: 1px solid #CED4DA;
        height: auto;
        width: 100%;
        background: #fff;
        overflow-y: auto;
        padding: 4px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.101961), 0px 1px 3px rgba(0, 0, 0, 0.0509804);
        border-radius: 10px;
        max-height: 200px;
        height: 200px;
        overflow: hidden;

        & span {
            color: var(--blue-dark);
        }

        &.show {
            display: block;
        }

      ul {
        margin-top: 0;
        margin-bottom: 0;
      }

         ul > li {
            padding: 7px 15px;
            cursor: pointer;
            &:hover {
                background-color: #e5e5e5;
            }
        }
    }

    &__icon {
        margin-left: 22px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__form {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .form-group {
            padding: 0;
            margin: 0;
            width: 100%;
        }
    }

    &__input {
        font-style: normal;
        font-weight: var(--header-controls-search-font-weight) !important;
        font-size: var(--header-controls-search-font-size) !important;
    }

    &__extended {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;

        &-text {
            font-style: normal;
            font-weight: var(--header-controls-search-font-weight);
            font-size: var(--header-controls-search-font-size);
            line-height: 15px;
            color: #4B4B4B;
            margin-right: 6px;
            &-visible {
                display: none;
            }
        }

        &-icon {
            margin-top: 1px;
        }
    }
}

.header-extended-search {
    background: #fff;
    border-bottom: 1px solid #E5E5E5;

    &__container {
        padding: 40px 20px 60px;

        @include media('>=sm') {
            padding: 30px 20px;
        }
    }
}

.nav-btn {
    &__is-opened {
        display: none;
    }
    &_opened {
        .nav-btn {
            &__is-closed {
                display: none;
            }
            &__is-opened {
                display: block;
            }
        }
    }
}

.header-search__form button {
    display: none;
}

.company-dropdown {
  padding: 20px;
  width: max-content;
  max-height: 306px;
  border-radius: 16px;
  box-shadow: 2px 4px 20px 0 rgba(0, 0, 0, 0.09);
  transform: translateX(-50%);
  overflow: auto;

    @media (min-width: 1200px){
        left: 50%;
        right:auto;
    }

    @media (max-width: 12000px){
        left:-60px;
        right:auto;
    }

  &:before {
    display: none;
  }

  &--search {
    position: relative;
    margin-bottom: 18px;

    input {
        font-size: var(--font-size-12);
        background-color: #F5F5F5 !important;
        border-radius: 36px;
        border: none;
        background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 10.5L9.81335 9.81335C9.44173 9.44173 9.25592 9.25592 9.08756 9.18061C8.89479 9.09438 8.82427 9.08173 8.61355 9.0956C8.42951 9.10771 8.07829 9.269 7.37586 9.59157C6.80484 9.8538 6.1695 10 5.5 10C3.01472 10 1 7.98528 1 5.5C1 3.01472 3.01472 1 5.5 1C7.98528 1 10 3.01472 10 5.5C10 6.1207 9.87433 6.71206 9.64705 7.25' stroke='%23C8C8C8' stroke-linecap='round'/%3E%3C/svg%3E%0A") no-repeat calc(100% - 12px) center;
        min-width: 260px;
        padding: 7px 12px;
        padding-right: 35px;
        &::placeholder {
            font-size: var(--font-size-12);
            color: var(--gray-light-opacity);
        }
    }
  }

  .dropdown__body-link {
    background: unset;
    position: relative;
    padding-left: 30px;
    padding-top: 8px;
    padding-bottom: 8px;

    font-family: Roboto;
    font-size: var(--font-size-12);
    font-style: var(--header-controls-search-font-weight);
    font-weight: 400;
    line-height: 150%; /* 19.5px */

    &, * {
      transition: all .3s ease-in-out;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #ECECEC;
    }

    &:before {
      transition: all .3s ease-in-out;
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid #CAC8E8;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &.active {
      color: var(--blue-dark);

      &:before {
        border-color: #4E43DF;
        background-color: #4E43DF;
      }

      &:after {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        transition: all .3s ease-in-out;
      }
    }

    &:hover {
      color: #4E43DF;

      &:before {
        border-color: #4E43DF;
      }
    }
  }
}


.icon-wrapper {
    position: relative;
    display: inline-flex;
}

.root-header__button-icon-with-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.burger-wrapper {
position: relative;
}
.without-gap{
    gap:0;

    &:hover{
        cursor:pointer;
        .root-header__button-text--with-icon {
            color: var(--blue-dark)
        }
    }
}
.favorite{
    gap:2px;
}
.link-logout {
    color: var(--red-light);
    &:hover,
    &:active {
        color: var(--red-light);
        text-decoration: underline;
    }

}

.go-to-favorite-products-btn__count, #compare-list-count{
    background-color: #eb5757;
    border: 1px solid #fff;
    border-radius: 32px;
    color: #fff;
    font-size: 12px;
    left: 55%;
        min-height: 16px;
    min-width: 16px;
    padding-inline: 3px;
    position: absolute;
    top: 10%;
    transform: translateY(-50%);
    line-height: 1.25;
}

.go-to-favorite-products-btn__count,
#compare-list-count,
#new_smallcart,
[data-notification-count]{
    opacity: 1;
}

#compare-list-count[value="0"],
#new_smallcart[value="0"],
.go-to-favorite-products-btn__count[data-count="0"],
[data-notification-count][value="0"] {
    opacity: 0;
}
#header {
    height: fit-content;
}
#visuallyImpaired {
    min-height: 58px;
}

#new_smallcart, [data-notification-count]{
  display:none;
}
.favorite-wrapper-header-icon {
    gar: 2px;
}

@media (max-width: 1200px) {
    .root-header__button-text,
    .root-header__button-text-personal {
        display: none;
    }
    .root-header__buttons-panel{
        margin-right: 5px;
    }
}
.profile-dropdown--contrast{
    font-weight: 700!important;
}
