.bi {
    display: inline-block;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 1em;
    height: 1em;
    min-width: 1em;
    &_mr {
        margin-left: 0px !important;
        margin-right: 5px !important;
    }
    &_ml {
        margin-left: 5px !important;
        margin-right: 0px !important;
    }
}

.bi-svg-dropdown-arrow {
    width: 18px;
    height: 18px;
    min-width: 18px;
    fill: #676767;
    &.blue {
        fill: #4D83D6;
    }
}

.bi-svg-profile {
    width: 20px;
    height: 20px;
    min-width: 20px;
    fill: #979797;
}
.bi-svg-profile-empty {
    width: 20px;
    height: 20px;
    min-width: 20px;
    fill: #4D83D6;
}

.bi-svg-user-profile-empty {
    width: 20px;
    height: 20px;
    min-width: 20px;
    fill: #4D83D6;
}
.bi-svg-user-profile-empty-thin {
    width: 29px;
    height: 29px;
    min-width: 29px;
    fill: #4D83D6;
}

.bi-svg-file-check {
    width: 18px;
    height: 18px;
    fill: #4D83D6;
}

.bi-svg-file {
    width: 20px;
    height: 20px;
    min-width: 20px;
    fill: #575757;
}

.bi-svg-file-import {
  width: 20px;
  height: 20px;
  fill: var(--color_theme_btn_primary_bg, #4d83d6);
}

.bi-svg-logout {
    width: 18px;
    height: 18px;
    min-width: 18px;
    fill: #EB5757;
}

.bi-svg-user {
    width: 18px;
    height: 18px;
    min-width: 18px;
    fill: #4F4F4F;
}

.bi-svg-wrench {
    width: 18px;
    height: 18px;
    min-width: 18px;
    fill: #4F4F4F;
}

.bi-svg-gear {
    width: 18px;
    height: 18px;
    min-width: 18px;
    fill: #4F4F4F;
}
.bi-svg-gear2 {
    width: 18px;
    height: 18px;
    min-width: 18px;
    fill: #4F4F4F;
}

.bi-svg-settings {
    width: 18px;
    height: 18px;
    min-width: 18px;
    fill: #4F4F4F;
    &.blue {
        fill: #4D83D6;
    }
    &.size-24 {
        width: 24px;
        height: 24px;
        min-width: 24px;
    }
}

.bi-svg-private-office {
    width: 18px;
    height: 18px;
    min-width: 18px;
    fill: #4F4F4F;
}

.bi-svg-edit {
    width: 20px;
    height: 20px;
    min-width: 18px;
    fill: #4F4F4F;

    &-white {
      width: 18px;
      height: 18px;
      fill: white;
    }
}

.bi-svg-edit-gray {
    width: 18px;
    height: 18px;
    min-width: 18px;
    fill: #979797;
  }

.bi-svg-wallet {
    width: 18px;
    height: 18px;
    min-width: 18px;
    fill: #4F4F4F;
}

.bi-svg-headphones {
    width: 18px;
    height: 18px;
    min-width: 18px;
    fill: #4F4F4F;
}

.bi-svg-users {
    width: 18px;
    height: 18px;
    min-width: 18px;
    fill: #4F4F4F;
}

.bi-svg-briefcase,
.bi-svg-exchange,
.bi-svg-informer {
    width: 20px;
    height: 20px;
    min-width: 20px;
    fill: #575757;
}
.bi-svg-briefcase-empty {
    width: 20px;
    height: 20px;
    min-width: 20px;
    fill: #4D83D6;
}

.bi-svg-menu {
    width: 24px;
    height: 24px;
    min-width: 24px;
}

.bi-svg-search {
    fill: #979797;
    width: 24px;
    height: 24px;
    min-width: 24px;
}

.bi-svg-catalog {
  fill: #fff;
  width: 24px;
  height: 24px;
  min-width: 24px;
}

.bi-svg-favorite,
.bi-svg-notifications,
.bi-svg-compare,
.bi-svg-scales {
    fill: transparent;
    stroke: #979797;
    width: 24px;
    height: 24px;
    min-width: 24px;
    &.btn__active {
      fill: transparent;
      stroke: #d93b31;
    }
}

.bi-svg-compare-list {
  fill: #979797;
  stroke: transparent;
  width: 24px;
  height: 24px;
  min-width: 24px;

  &.btn__active {
    fill: #d93b31;
    stroke: transparent;
  }
}

.bi-svg-favorite {
  &.btn__active,
  &.main-header__btn-icon.btn__active,
  &.btn_favorite__active {
    fill: #d93b31;
  }
}

.bi-svg-empty-heart {
    fill: #979797;
    width: 20px;
    min-width: 20px;
    height: 20px;
}

.bi-svg-cart {
    width: 24px;
    height: 24px;
    min-width: 24px;
}
.bi-svg-cart-empty {
    width: 24px;
    height: 24px;
    min-width: 24px;
    &.white {
        fill: #FFFFFF;
    }
}

.bi-svg-dots {
    fill: none;
    stroke: #676767;
    width: 18px;
    height: 18px;
    min-width: 18px;
}

.bi-svg-dots-regular {
  fill: #676767;
  width: 19.5px;
  height: 6px;
  min-width: 19.5px;
}

.bi-svg-sidebar-nav-icon {
    fill: none;
    stroke: #4D83D6;
    width: 20px;
    height: 20px;
    min-width: 20px;
    &.fill {
        fill: #4D83D6;
        stroke: none;
    }
}

.bi-svg-uptrade-logo {
    width: 115px;
    min-width: 115px;
    height: 25px;
    fill: #4D83D6;
    &.white {
        fill: #fff;
    }
}

.bi-svg-facebook {
    width: 13px;
    min-width: 13px;
    height: 27px;
    fill: #fff;
}

.bi-svg-youtube {
    width: 31px;
    min-width: 31px;
    height: 31px;
    fill: #fff;
}

.bi-svg-instagram {
    width: 31px;
    min-width: 31px;
    height: 31px;
    fill: #fff;
}

.bi-svg-cross {
    width: 24px;
    min-width: 24px;
    height: 24px;
    fill: #979797;
    &.white {
        fill: #fff;
    }
    &.blue {
        fill: #4D83D6;
    }
    &.dark-blue{
        fill: var(--blue-dark);
    }
    &.size-24 {
        width: 24px;
        min-width: 24px;
        height: 24px;
    }
}

.bi-svg-btn-search {
    width: 18px;
    min-width: 18px;
    height: 19px;
    fill: #fff;
}

.bi-svg-btn-trash {
    width: 18px;
    min-width: 18px;
    height: 19px;
    fill: #292929;
}

.bi-svg-folder-open {
    width: 20px;
    min-width: 20px;
    height: 20px;
    fill: #22282F;
}

.bi-svg-phone {
    width: 18px;
    min-width: 18px;
    height: 18px;
    fill: #22282F;
}

.bi-svg-bell {
    width: 24px;
    min-width: 24px;
    height: 24px;
    fill: #868B9A;
    &.btn__active {
      fill: #d93b31;
    }
    &_lite {
      fill: #B9B9B9;
    }
}

.bi-svg-comment {
    width: 24px;
    min-width: 24px;
    height: 24px;
    fill: #83829D;
    &.btn__active {
      fill: #d93b31;
    }
}

.bi-svg-chevron-left {
    width: 24px;
    min-width: 24px;
    height: 24px;
    fill: currentColor;
}

.bi-svg-nav-icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
    fill: #868B9A;
}

.bi-svg-radio-icon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  fill: white;
}

.bi-svg-radio-icon-active {
  width: 20px;
  min-width: 20px;
  height: 20px;
  fill: #4D83D6;
}

.bi-notebook {
  background-image: url('./uptrade/icons/notebook.svg');
  width: 16px;
  height: 16px;
}
.bi-compass {
  background-image: url('./uptrade/icons/compass.svg');
  width: 16px;
  height: 16px;
}
.bi-headphones {
  background-image: url('./uptrade/icons/headphones.svg');
  width: 16px;
  height: 16px;
}
.bi-distribute-vertically {
  background-image: url('./uptrade/icons/distribute-vertically.svg');
  width: 16px;
  height: 16px;
}
.bi-plus {
  background-image: url('./uptrade/icons/plus.svg');
  width: 16px;
  height: 16px;
}
.bi-user-arrowtr {
  background-image: url('./uptrade/icons/user-arrowtr.svg');
  width: 16px;
  height: 16px;
}
.bi-call-decline {
  background-image: url('./uptrade/icons/call-decline.svg');
  width: 16px;
  height: 16px;
}
.bi-lineheight-2 {
  background-image: url('./uptrade/icons/lineheight-2.svg');
  width: 16px;
  height: 16px;
}
.bi-replace {
  background-image: url('./uptrade/icons/replace.svg');
  width: 16px;
  height: 16px;
}
.bi-clock-check {
  background-image: url('./uptrade/icons/clock-check.svg');
  width: 16px;
  height: 16px;
}
.bi-quotes-2 {
  background-image: url('./uptrade/icons/quotes-2.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-slash {
  background-image: url('./uptrade/icons/folder-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-scales {
  background-image: url('./uptrade/icons/scales.svg');
  width: 16px;
  height: 16px;
}
.bi-miniplayer {
  background-image: url('./uptrade/icons/miniplayer.svg');
  width: 16px;
  height: 16px;
}
.bi-sliders-horiz {
  background-image: url('./uptrade/icons/sliders-horiz.svg');
  width: 16px;
  height: 16px;
}
.bi-rocket {
  background-image: url('./uptrade/icons/rocket.svg');
  width: 16px;
  height: 16px;
}
.bi-volume-low {
  background-image: url('./uptrade/icons/volume-low.svg');
  width: 16px;
  height: 16px;
}
.bi-search {
  background-image: url('./uptrade/icons/search.svg');
  width: 16px;
  height: 16px;
}
.bi-play-next {
  background-image: url('./uptrade/icons/play-next.svg');
  width: 16px;
  height: 16px;
}
.bi-file-shredder {
  background-image: url('./uptrade/icons/file-shredder.svg');
  width: 16px;
  height: 16px;
}
.bi-arrows-move-2 {
  background-image: url('./uptrade/icons/arrows-move-2.svg');
  width: 16px;
  height: 16px;
}
.bi-time {
  background-image: url('./uptrade/icons/time.svg');
  width: 16px;
  height: 16px;
}
.bi-droplet {
  background-image: url('./uptrade/icons/droplet.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-text {
  background-image: url('./uptrade/icons/comment-text.svg');
  width: 16px;
  height: 16px;
}
.bi-export-2 {
  background-image: url('./uptrade/icons/export-2.svg');
  width: 16px;
  height: 16px;
}
.bi-file-download {
  background-image: url('./uptrade/icons/file-download.svg');
  width: 16px;
  height: 16px;
}
.bi-videocamera {
  background-image: url('./uptrade/icons/videocamera.svg');
  width: 16px;
  height: 16px;
}
.bi-arrows-expand {
  background-image: url('./uptrade/icons/arrows-expand.svg');
  width: 16px;
  height: 16px;
}
.bi-plus-square {
  background-image: url('./uptrade/icons/plus-square.svg');
  width: 16px;
  height: 16px;
}
.bi-lock-opened {
  background-image: url('./uptrade/icons/lock-opened.svg');
  width: 16px;
  height: 16px;
}
.bi-webcam {
  background-image: url('./uptrade/icons/webcam.svg');
  width: 16px;
  height: 16px;
}
.bi-user-arrowbl {
  background-image: url('./uptrade/icons/user-arrowbl.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-bottom {
  background-image: url('./uptrade/icons/arrow-bottom.svg');
  width: 16px;
  height: 16px;
}
.bi-border-inner {
  background-image: url('./uptrade/icons/border-inner.svg');
  width: 16px;
  height: 16px;
}
.bi-import-3 {
  background-image: url('./uptrade/icons/import-3.svg');
  width: 16px;
  height: 16px;
}
.bi-dollar {
  background-image: url('./uptrade/icons/dollar.svg');
  width: 16px;
  height: 16px;
}
.bi-emotion-neutral {
  background-image: url('./uptrade/icons/emotion-neutral.svg');
  width: 16px;
  height: 16px;
}
.bi-apperture {
  background-image: url('./uptrade/icons/apperture.svg');
  width: 16px;
  height: 16px;
}
.bi-externallink-2 {
  background-image: url('./uptrade/icons/externallink-2.svg');
  width: 16px;
  height: 16px;
}
.bi-lineheight {
  background-image: url('./uptrade/icons/lineheight.svg');
  width: 16px;
  height: 16px;
}
.bi-zoom-in {
  background-image: url('./uptrade/icons/zoom-in.svg');
  width: 16px;
  height: 16px;
}
.bi-align-bottom {
  background-image: url('./uptrade/icons/align-bottom.svg');
  width: 16px;
  height: 16px;
}
.bi-replyall {
  background-image: url('./uptrade/icons/replyall.svg');
  width: 16px;
  height: 16px;
}
.bi-replay {
  background-image: url('./uptrade/icons/replay.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-right-circle {
  background-image: url('./uptrade/icons/arrow-right-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-server-2 {
  background-image: url('./uptrade/icons/server-2.svg');
  width: 16px;
  height: 16px;
}
.bi-webcam-slash {
  background-image: url('./uptrade/icons/webcam-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-hashtag {
  background-image: url('./uptrade/icons/hashtag.svg');
  width: 16px;
  height: 16px;
}
.bi-zoom-out {
  background-image: url('./uptrade/icons/zoom-out.svg');
  width: 16px;
  height: 16px;
}
.bi-gift {
  background-image: url('./uptrade/icons/gift.svg');
  width: 16px;
  height: 16px;
}
.bi-border-right {
  background-image: url('./uptrade/icons/border-right.svg');
  width: 16px;
  height: 16px;
}
.bi-more-vert {
  background-image: url('./uptrade/icons/more-vert.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-corner-ccw-rt {
  background-image: url('./uptrade/icons/arrow-corner-ccw-rt.svg');
  width: 16px;
  height: 16px;
}
.bi-gear-2 {
  background-image: url('./uptrade/icons/gear-2.svg');
  width: 16px;
  height: 16px;
}
.bi-textblock {
  background-image: url('./uptrade/icons/textblock.svg');
  width: 16px;
  height: 16px;
}
.bi-chevrons-left {
  background-image: url('./uptrade/icons/chevrons-left.svg');
  width: 16px;
  height: 16px;
}
.bi-minus-octagon {
  background-image: url('./uptrade/icons/minus-octagon.svg');
  width: 16px;
  height: 16px;
}
.bi-user-plus {
  background-image: url('./uptrade/icons/user-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-crosshair {
  background-image: url('./uptrade/icons/crosshair.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-top {
  background-image: url('./uptrade/icons/arrow-top.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-corner-ccw-lt {
  background-image: url('./uptrade/icons/arrow-corner-ccw-lt.svg');
  width: 16px;
  height: 16px;
}
.bi-cloud-download {
  background-image: url('./uptrade/icons/cloud-download.svg');
  width: 16px;
  height: 16px;
}
.bi-keyboard-show {
  background-image: url('./uptrade/icons/keyboard-show.svg');
  width: 16px;
  height: 16px;
}
.bi-lightning {
  background-image: url('./uptrade/icons/lightning.svg');
  width: 16px;
  height: 16px;
}
.bi-alert-octagon {
  background-image: url('./uptrade/icons/alert-octagon.svg');
  width: 16px;
  height: 16px;
}
.bi-distribute-horizontally {
  background-image: url('./uptrade/icons/distribute-horizontally.svg');
  width: 16px;
  height: 16px;
}
.bi-columnspacing {
  background-image: url('./uptrade/icons/columnspacing.svg');
  width: 16px;
  height: 16px;
}
.bi-file-draft {
  background-image: url('./uptrade/icons/file-draft.svg');
  width: 16px;
  height: 16px;
}
.bi-crop {
  background-image: url('./uptrade/icons/crop.svg');
  width: 16px;
  height: 16px;
}
.bi-sishka {
  background-image: url('./uptrade/icons/sishka.svg');
  width: 16px;
  height: 16px;
}
.bi-smartphone {
  background-image: url('./uptrade/icons/smartphone.svg');
  width: 16px;
  height: 16px;
}
.bi-label-4k {
  background-image: url('./uptrade/icons/label-4k.svg');
  width: 16px;
  height: 16px;
}
.bi-plug {
  background-image: url('./uptrade/icons/plug.svg');
  width: 16px;
  height: 16px;
}
.bi-border-vertical {
  background-image: url('./uptrade/icons/border-vertical.svg');
  width: 16px;
  height: 16px;
}
.bi-cross-circle {
  background-image: url('./uptrade/icons/cross-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-soundwave {
  background-image: url('./uptrade/icons/soundwave.svg');
  width: 16px;
  height: 16px;
}
.bi-calendar-cross {
  background-image: url('./uptrade/icons/calendar-cross.svg');
  width: 16px;
  height: 16px;
}
.bi-bell {
  background-image: url('./uptrade/icons/bell.svg');
  width: 16px;
  height: 16px;
}
.bi-send {
  background-image: url('./uptrade/icons/send.svg');
  width: 16px;
  height: 16px;
}
.bi-comment {
  background-image: url('./uptrade/icons/comment.svg');
  width: 16px;
  height: 16px;
}
.bi-flag-2 {
  background-image: url('./uptrade/icons/flag-2.svg');
  width: 16px;
  height: 16px;
}
.bi-share {
  background-image: url('./uptrade/icons/share.svg');
  width: 16px;
  height: 16px;
}
.bi-functions {
  background-image: url('./uptrade/icons/functions.svg');
  width: 16px;
  height: 16px;
}
.bi-briefcase-2 {
  background-image: url('./uptrade/icons/briefcase-2.svg');
  width: 16px;
  height: 16px;
}
.bi-brackets {
  background-image: url('./uptrade/icons/brackets.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-left {
  background-image: url('./uptrade/icons/arrow-left.svg');
  width: 16px;
  height: 16px;
}
.bi-newspaper {
  background-image: url('./uptrade/icons/newspaper.svg');
  width: 16px;
  height: 16px;
}
.bi-info {
  background-image: url('./uptrade/icons/info.svg');
  width: 16px;
  height: 16px;
}
.bi-clock-plus {
  background-image: url('./uptrade/icons/clock-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-call-missed {
  background-image: url('./uptrade/icons/call-missed.svg');
  width: 16px;
  height: 16px;
}
.bi-comments {
  background-image: url('./uptrade/icons/comments.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-13 {
  background-image: url('./uptrade/icons/grid-13.svg');
  width: 16px;
  height: 16px;
}
.bi-shield-cross {
  background-image: url('./uptrade/icons/shield-cross.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-06 {
  background-image: url('./uptrade/icons/grid-06.svg');
  width: 16px;
  height: 16px;
}
.bi-newrelease {
  background-image: url('./uptrade/icons/newrelease.svg');
  width: 16px;
  height: 16px;
}
.bi-label-explicit {
  background-image: url('./uptrade/icons/label-explicit.svg');
  width: 16px;
  height: 16px;
}
.bi-arrows-expand-2 {
  background-image: url('./uptrade/icons/arrows-expand-2.svg');
  width: 16px;
  height: 16px;
}
.bi-chevron-down {
  background-image: url('./uptrade/icons/chevron-down.svg');
  width: 16px;
  height: 16px;
}
.bi-star {
  background-image: url('./uptrade/icons/star.svg');
  width: 16px;
  height: 16px;
}
.bi-volume-mute {
  background-image: url('./uptrade/icons/volume-mute.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-open {
  background-image: url('./uptrade/icons/folder-open.svg');
  width: 16px;
  height: 16px;
}
.bi-calendar-arrowright {
  background-image: url('./uptrade/icons/calendar-arrowright.svg');
  width: 16px;
  height: 16px;
}
.bi-planet {
  background-image: url('./uptrade/icons/planet.svg');
  width: 16px;
  height: 16px;
}
.bi-sunset {
  background-image: url('./uptrade/icons/sunset.svg');
  width: 16px;
  height: 16px;
}
.bi-calendar-note {
  background-image: url('./uptrade/icons/calendar-note.svg');
  width: 16px;
  height: 16px;
}
.bi-text-2 {
  background-image: url('./uptrade/icons/text-2.svg');
  width: 16px;
  height: 16px;
}
.bi-border-bottom {
  background-image: url('./uptrade/icons/border-bottom.svg');
  width: 16px;
  height: 16px;
}
.bi-users {
  background-image: url('./uptrade/icons/users.svg');
  width: 16px;
  height: 16px;
}
.bi-atom {
  background-image: url('./uptrade/icons/atom.svg');
  width: 16px;
  height: 16px;
}
.bi-tag-2 {
  background-image: url('./uptrade/icons/tag-2.svg');
  width: 16px;
  height: 16px;
}
.bi-undo {
  background-image: url('./uptrade/icons/undo.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-check {
  background-image: url('./uptrade/icons/comment-check.svg');
  width: 16px;
  height: 16px;
}
.bi-shield-check {
  background-image: url('./uptrade/icons/shield-check.svg');
  width: 16px;
  height: 16px;
}
.bi-wraptext {
  background-image: url('./uptrade/icons/wraptext.svg');
  width: 16px;
  height: 16px;
}
.bi-image-2 {
  background-image: url('./uptrade/icons/image-2.svg');
  width: 16px;
  height: 16px;
}
.bi-file-heart {
  background-image: url('./uptrade/icons/file-heart.svg');
  width: 16px;
  height: 16px;
}
.bi-reply {
  background-image: url('./uptrade/icons/reply.svg');
  width: 16px;
  height: 16px;
}
.bi-emotion-smile {
  background-image: url('./uptrade/icons/emotion-smile.svg');
  width: 16px;
  height: 16px;
}
.bi-image-slash {
  background-image: url('./uptrade/icons/image-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-keyboard-hide {
  background-image: url('./uptrade/icons/keyboard-hide.svg');
  width: 16px;
  height: 16px;
}
.bi-sliders-vert {
  background-image: url('./uptrade/icons/sliders-vert.svg');
  width: 16px;
  height: 16px;
}
.bi-droplet-slash {
  background-image: url('./uptrade/icons/droplet-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-dumbell {
  background-image: url('./uptrade/icons/dumbell.svg');
  width: 16px;
  height: 16px;
}
.bi-cloud-upload {
  background-image: url('./uptrade/icons/cloud-upload.svg');
  width: 16px;
  height: 16px;
}
.bi-checkbox-square {
  background-image: url('./uptrade/icons/checkbox-square.svg');
  width: 16px;
  height: 16px;
}
.bi-crop-rotate {
  background-image: url('./uptrade/icons/crop-rotate.svg');
  width: 16px;
  height: 16px;
}
.bi-slider-vertical-2 {
  background-image: url('./uptrade/icons/slider-vertical-2.svg');
  width: 16px;
  height: 16px;
}
.bi-emails {
  background-image: url('./uptrade/icons/emails.svg');
  width: 16px;
  height: 16px;
}
.bi-calendar {
  background-image: url('./uptrade/icons/calendar.svg');
  width: 16px;
  height: 16px;
}
.bi-dialpad {
  background-image: url('./uptrade/icons/dialpad.svg');
  width: 16px;
  height: 16px;
}
.bi-filter-2 {
  background-image: url('./uptrade/icons/filter-2.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-bottomright {
  background-image: url('./uptrade/icons/arrow-bottomright.svg');
  width: 16px;
  height: 16px;
}
.bi-forward-15 {
  background-image: url('./uptrade/icons/forward-15.svg');
  width: 16px;
  height: 16px;
}
.bi-label-hq {
  background-image: url('./uptrade/icons/label-hq.svg');
  width: 16px;
  height: 16px;
}
.bi-musicqueue {
  background-image: url('./uptrade/icons/musicqueue.svg');
  width: 16px;
  height: 16px;
}
.bi-terminal {
  background-image: url('./uptrade/icons/terminal.svg');
  width: 16px;
  height: 16px;
}
.bi-refresh-cw {
  background-image: url('./uptrade/icons/refresh-cw.svg');
  width: 16px;
  height: 16px;
}
.bi-forward-10 {
  background-image: url('./uptrade/icons/forward-10.svg');
  width: 16px;
  height: 16px;
}
.bi-label-hd {
  background-image: url('./uptrade/icons/label-hd.svg');
  width: 16px;
  height: 16px;
}
.bi-battery-3 {
  background-image: url('./uptrade/icons/battery-3.svg');
  width: 16px;
  height: 16px;
}
.bi-games {
  background-image: url('./uptrade/icons/games.svg');
  width: 16px;
  height: 16px;
}
.bi-chart-pie {
  background-image: url('./uptrade/icons/chart-pie.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-corner-cw-lb {
  background-image: url('./uptrade/icons/arrow-corner-cw-lb.svg');
  width: 16px;
  height: 16px;
}
.bi-shoppingcart {
  background-image: url('./uptrade/icons/shoppingcart.svg');
  width: 16px;
  height: 16px;
}
.bi-lock {
  background-image: url('./uptrade/icons/lock.svg');
  width: 16px;
  height: 16px;
}
.bi-faceid {
  background-image: url('./uptrade/icons/faceid.svg');
  width: 16px;
  height: 16px;
}
.bi-gps {
  background-image: url('./uptrade/icons/gps.svg');
  width: 16px;
  height: 16px;
}
.bi-flag {
  background-image: url('./uptrade/icons/flag.svg');
  width: 16px;
  height: 16px;
}
.bi-clock-cross {
  background-image: url('./uptrade/icons/clock-cross.svg');
  width: 16px;
  height: 16px;
}
.bi-building {
  background-image: url('./uptrade/icons/building.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-corner-cw-lt {
  background-image: url('./uptrade/icons/arrow-corner-cw-lt.svg');
  width: 16px;
  height: 16px;
}
.bi-note-text {
  background-image: url('./uptrade/icons/note-text.svg');
  width: 16px;
  height: 16px;
}
.bi-image-upload {
  background-image: url('./uptrade/icons/image-upload.svg');
  width: 16px;
  height: 16px;
}
.bi-edit {
  background-image: url('./uptrade/icons/edit.svg');
  width: 16px;
  height: 16px;
}
.bi-gear {
  background-image: url('./uptrade/icons/gear.svg');
  width: 16px;
  height: 16px;
}
.bi-euro {
  background-image: url('./uptrade/icons/euro.svg');
  width: 16px;
  height: 16px;
}
.bi-sort-by {
  background-image: url('./uptrade/icons/sort-by.svg');
  width: 16px;
  height: 16px;
}
.bi-slider-vertical {
  background-image: url('./uptrade/icons/slider-vertical.svg');
  width: 16px;
  height: 16px;
}
.bi-iframe {
  background-image: url('./uptrade/icons/iframe.svg');
  width: 16px;
  height: 16px;
}
.bi-chevrons-up {
  background-image: url('./uptrade/icons/chevrons-up.svg');
  width: 16px;
  height: 16px;
}
.bi-file-minus {
  background-image: url('./uptrade/icons/file-minus.svg');
  width: 16px;
  height: 16px;
}
.bi-cut {
  background-image: url('./uptrade/icons/cut.svg');
  width: 16px;
  height: 16px;
}
.bi-cloud {
  background-image: url('./uptrade/icons/cloud.svg');
  width: 16px;
  height: 16px;
}
.bi-play-slowmotion {
  background-image: url('./uptrade/icons/play-slowmotion.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-07 {
  background-image: url('./uptrade/icons/grid-07.svg');
  width: 16px;
  height: 16px;
}
.bi-infinity {
  background-image: url('./uptrade/icons/infinity.svg');
  width: 16px;
  height: 16px;
}
.bi-dollar-circle {
  background-image: url('./uptrade/icons/dollar-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-letterspacing-2 {
  background-image: url('./uptrade/icons/letterspacing-2.svg');
  width: 16px;
  height: 16px;
}
.bi-music-plus {
  background-image: url('./uptrade/icons/music-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-video-plus {
  background-image: url('./uptrade/icons/video-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-shoppingcart-2 {
  background-image: url('./uptrade/icons/shoppingcart-2.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-05 {
  background-image: url('./uptrade/icons/grid-05.svg');
  width: 16px;
  height: 16px;
}
.bi-call-add {
  background-image: url('./uptrade/icons/call-add.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-10 {
  background-image: url('./uptrade/icons/grid-10.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-2-text {
  background-image: url('./uptrade/icons/comment-2-text.svg');
  width: 16px;
  height: 16px;
}
.bi-wifi-slash {
  background-image: url('./uptrade/icons/wifi-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-pause-circle {
  background-image: url('./uptrade/icons/pause-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-link-2 {
  background-image: url('./uptrade/icons/link-2.svg');
  width: 16px;
  height: 16px;
}
.bi-folder2 {
  background-image: url('./uptrade/icons/folder2.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-swap {
  background-image: url('./uptrade/icons/arrow-swap.svg');
  width: 16px;
  height: 16px;
}
.bi-cloud-snow {
  background-image: url('./uptrade/icons/cloud-snow.svg');
  width: 16px;
  height: 16px;
}
.bi-user-arrowbottom {
  background-image: url('./uptrade/icons/user-arrowbottom.svg');
  width: 16px;
  height: 16px;
}
.bi-indent-increase {
  background-image: url('./uptrade/icons/indent-increase.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-2-question {
  background-image: url('./uptrade/icons/comment-2-question.svg');
  width: 16px;
  height: 16px;
}
.bi-list-ordered-2 {
  background-image: url('./uptrade/icons/list-ordered-2.svg');
  width: 16px;
  height: 16px;
}
.bi-shoppingbasket {
  background-image: url('./uptrade/icons/shoppingbasket.svg');
  width: 16px;
  height: 16px;
}
.bi-eye-slash {
  background-image: url('./uptrade/icons/eye-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-lock-2-slash {
  background-image: url('./uptrade/icons/lock-2-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-chevron-up {
  background-image: url('./uptrade/icons/chevron-up.svg');
  width: 16px;
  height: 16px;
}
.bi-image-check {
  background-image: url('./uptrade/icons/image-check.svg');
  width: 16px;
  height: 16px;
}
.bi-diskette {
  background-image: url('./uptrade/icons/diskette.svg');
  width: 16px;
  height: 16px;
}
.bi-slider-horizontal {
  background-image: url('./uptrade/icons/slider-horizontal.svg');
  width: 16px;
  height: 16px;
}
.bi-chevron-left {
  background-image: url('./uptrade/icons/chevron-left.svg');
  width: 16px;
  height: 16px;
}
.bi-link-1 {
  background-image: url('./uptrade/icons/link-1.svg');
  width: 16px;
  height: 16px;
}
.bi-maximize-2-1 {
  background-image: url('./uptrade/icons/maximize-2-1.svg');
  width: 16px;
  height: 16px;
}
.bi-text-underline {
  background-image: url('./uptrade/icons/text-underline.svg');
  width: 16px;
  height: 16px;
}
.bi-call-end {
  background-image: url('./uptrade/icons/call-end.svg');
  width: 16px;
  height: 16px;
}
.bi-link-2-1 {
  background-image: url('./uptrade/icons/link-2-1.svg');
  width: 16px;
  height: 16px;
}
.bi-tv {
  background-image: url('./uptrade/icons/tv.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-12 {
  background-image: url('./uptrade/icons/grid-12.svg');
  width: 16px;
  height: 16px;
}
.bi-slider-horizontal-2 {
  background-image: url('./uptrade/icons/slider-horizontal-2.svg');
  width: 16px;
  height: 16px;
}
.bi-pet {
  background-image: url('./uptrade/icons/pet.svg');
  width: 16px;
  height: 16px;
}
.bi-bell-minus {
  background-image: url('./uptrade/icons/bell-minus.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-plus {
  background-image: url('./uptrade/icons/folder-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-target {
  background-image: url('./uptrade/icons/target.svg');
  width: 16px;
  height: 16px;
}
.bi-inbox-filled {
  background-image: url('./uptrade/icons/inbox-filled.svg');
  width: 16px;
  height: 16px;
}
.bi-bookmark-plus {
  background-image: url('./uptrade/icons/bookmark-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-bulb-slash {
  background-image: url('./uptrade/icons/bulb-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-sort-text {
  background-image: url('./uptrade/icons/sort-text.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-2-dots {
  background-image: url('./uptrade/icons/comment-2-dots.svg');
  width: 16px;
  height: 16px;
}
.bi-fire {
  background-image: url('./uptrade/icons/fire.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-slash {
  background-image: url('./uptrade/icons/comment-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-book-plus {
  background-image: url('./uptrade/icons/book-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-align-left-2 {
  background-image: url('./uptrade/icons/align-left-2.svg');
  width: 16px;
  height: 16px;
}
.bi-creditcard-outcome {
  background-image: url('./uptrade/icons/creditcard-outcome.svg');
  width: 16px;
  height: 16px;
}
.bi-voicemail {
  background-image: url('./uptrade/icons/voicemail.svg');
  width: 16px;
  height: 16px;
}
.bi-emotion-sad {
  background-image: url('./uptrade/icons/emotion-sad.svg');
  width: 16px;
  height: 16px;
}
.bi-mouse {
  background-image: url('./uptrade/icons/mouse.svg');
  width: 16px;
  height: 16px;
}
.bi-tags {
  background-image: url('./uptrade/icons/tags.svg');
  width: 16px;
  height: 16px;
}
.bi-umbrella {
  background-image: url('./uptrade/icons/umbrella.svg');
  width: 16px;
  height: 16px;
}
.bi-shoppingbag-2 {
  background-image: url('./uptrade/icons/shoppingbag-2.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-2-minus {
  background-image: url('./uptrade/icons/comment-2-minus.svg');
  width: 16px;
  height: 16px;
}
.bi-equalizer {
  background-image: url('./uptrade/icons/equalizer.svg');
  width: 16px;
  height: 16px;
}
.bi-dashboard {
  background-image: url('./uptrade/icons/dashboard.svg');
  width: 16px;
  height: 16px;
}
.bi-anchor {
  background-image: url('./uptrade/icons/anchor.svg');
  width: 16px;
  height: 16px;
}
.bi-loadingtrolley {
  background-image: url('./uptrade/icons/loadingtrolley.svg');
  width: 16px;
  height: 16px;
}
.bi-lightning-slash {
  background-image: url('./uptrade/icons/lightning-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-left-curved-circle {
  background-image: url('./uptrade/icons/arrow-left-curved-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-calendar-number {
  background-image: url('./uptrade/icons/calendar-number.svg');
  width: 16px;
  height: 16px;
}
.bi-bookmark-check {
  background-image: url('./uptrade/icons/bookmark-check.svg');
  width: 16px;
  height: 16px;
}
.bi-cross {
  background-image: url('./uptrade/icons/cross.svg');
  width: 16px;
  height: 16px;
}
.bi-layers {
  background-image: url('./uptrade/icons/layers.svg');
  width: 16px;
  height: 16px;
}
.bi-wallet-plus-1 {
  background-image: url('./uptrade/icons/wallet-plus-1.svg');
  width: 16px;
  height: 16px;
}
.bi-pentool-2 {
  background-image: url('./uptrade/icons/pentool-2.svg');
  width: 16px;
  height: 16px;
}
.bi-fingerprint {
  background-image: url('./uptrade/icons/fingerprint.svg');
  width: 16px;
  height: 16px;
}
.bi-note-2-text {
  background-image: url('./uptrade/icons/note-2-text.svg');
  width: 16px;
  height: 16px;
}
.bi-camera-slash {
  background-image: url('./uptrade/icons/camera-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-dots {
  background-image: url('./uptrade/icons/comment-dots.svg');
  width: 16px;
  height: 16px;
}
.bi-externallink {
  background-image: url('./uptrade/icons/externallink.svg');
  width: 16px;
  height: 16px;
}
.bi-pos {
  background-image: url('./uptrade/icons/pos.svg');
  width: 16px;
  height: 16px;
}
.bi-gps-slash {
  background-image: url('./uptrade/icons/gps-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-15 {
  background-image: url('./uptrade/icons/grid-15.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-2-cancel {
  background-image: url('./uptrade/icons/comment-2-cancel.svg');
  width: 16px;
  height: 16px;
}
.bi-user-alert {
  background-image: url('./uptrade/icons/user-alert.svg');
  width: 16px;
  height: 16px;
}
.bi-file-plus {
  background-image: url('./uptrade/icons/file-plus.svg');
  width: 16px;
  height: 16px;
}

.bi-euro-circle {
  background-image: url('./uptrade/icons/euro-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-file-upload {
  background-image: url('./uptrade/icons/file-upload.svg');
  width: 16px;
  height: 16px;
}
.bi-wallet {
  background-image: url('./uptrade/icons/wallet.svg');
  width: 16px;
  height: 16px;
}
.bi-user-minus {
  background-image: url('./uptrade/icons/user-minus.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-bottom-circle {
  background-image: url('./uptrade/icons/arrow-bottom-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-heart {
  background-image: url('./uptrade/icons/folder-heart.svg');
  width: 16px;
  height: 16px;
}
.bi-redo-2 {
  background-image: url('./uptrade/icons/redo-2.svg');
  width: 16px;
  height: 16px;
}
.bi-printer-slash {
  background-image: url('./uptrade/icons/printer-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-list-unordered-2 {
  background-image: url('./uptrade/icons/list-unordered-2.svg');
  width: 16px;
  height: 16px;
}
.bi-align-justify {
  background-image: url('./uptrade/icons/align-justify.svg');
  width: 16px;
  height: 16px;
}
.bi-user-square {
  background-image: url('./uptrade/icons/user-square.svg');
  width: 16px;
  height: 16px;
}
.bi-chart-bar {
  background-image: url('./uptrade/icons/chart-bar.svg');
  width: 16px;
  height: 16px;
}
.bi-refresh-ccw {
  background-image: url('./uptrade/icons/refresh-ccw.svg');
  width: 16px;
  height: 16px;
}
.bi-call-muted {
  background-image: url('./uptrade/icons/call-muted.svg');
  width: 16px;
  height: 16px;
}
.bi-wifi {
  background-image: url('./uptrade/icons/wifi.svg');
  width: 16px;
  height: 16px;
}
.bi-transform {
  background-image: url('./uptrade/icons/transform.svg');
  width: 16px;
  height: 16px;
}
.bi-loader {
  background-image: url('./uptrade/icons/loader.svg');
  width: 16px;
  height: 16px;
}
.bi-filmboard {
  background-image: url('./uptrade/icons/filmboard.svg');
  width: 16px;
  height: 16px;
}
.bi-more-horiz {
  background-image: url('./uptrade/icons/more-horiz.svg');
  width: 16px;
  height: 16px;
}
.bi-text-slash {
  background-image: url('./uptrade/icons/text-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-pin-check {
  background-image: url('./uptrade/icons/pin-check.svg');
  width: 16px;
  height: 16px;
}
.bi-glasses {
  background-image: url('./uptrade/icons/glasses.svg');
  width: 16px;
  height: 16px;
}
.bi-paperclip-2 {
  background-image: url('./uptrade/icons/paperclip-2.svg');
  width: 16px;
  height: 16px;
}
.bi-timer-2 {
  background-image: url('./uptrade/icons/timer-2.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-2-info {
  background-image: url('./uptrade/icons/comment-2-info.svg');
  width: 16px;
  height: 16px;
}
.bi-videolibrary {
  background-image: url('./uptrade/icons/videolibrary.svg');
  width: 16px;
  height: 16px;
}
.bi-inbox-out {
  background-image: url('./uptrade/icons/inbox-out.svg');
  width: 16px;
  height: 16px;
}
.bi-cross-octagon {
  background-image: url('./uptrade/icons/cross-octagon.svg');
  width: 16px;
  height: 16px;
}
.bi-cast {
  background-image: url('./uptrade/icons/cast.svg');
  width: 16px;
  height: 16px;
}
.bi-verified {
  background-image: url('./uptrade/icons/verified.svg');
  width: 16px;
  height: 16px;
}
.bi-cloud-drizzle {
  background-image: url('./uptrade/icons/cloud-drizzle.svg');
  width: 16px;
  height: 16px;
}
.bi-refresh-cw-alert {
  background-image: url('./uptrade/icons/refresh-cw-alert.svg');
  width: 16px;
  height: 16px;
}
.bi-user-arrowtop {
  background-image: url('./uptrade/icons/user-arrowtop.svg');
  width: 16px;
  height: 16px;
}
.bi-pentool {
  background-image: url('./uptrade/icons/pentool.svg');
  width: 16px;
  height: 16px;
}
.bi-calendar-person {
  background-image: url('./uptrade/icons/calendar-person.svg');
  width: 16px;
  height: 16px;
}
.bi-chart-line {
  background-image: url('./uptrade/icons/chart-line.svg');
  width: 16px;
  height: 16px;
}
.bi-speaker {
  background-image: url('./uptrade/icons/speaker.svg');
  width: 16px;
  height: 16px;
}
.bi-creditcard-plus {
  background-image: url('./uptrade/icons/creditcard-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-play-circle {
  background-image: url('./uptrade/icons/play-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-repeat {
  background-image: url('./uptrade/icons/repeat.svg');
  width: 16px;
  height: 16px;
}
.bi-forward {
  background-image: url('./uptrade/icons/forward.svg');
  width: 16px;
  height: 16px;
}
.bi-printer {
  background-image: url('./uptrade/icons/printer.svg');
  width: 16px;
  height: 16px;
}
.bi-alignmiddle {
  background-image: url('./uptrade/icons/alignmiddle.svg');
  width: 16px;
  height: 16px;
}
.bi-heart-slash {
  background-image: url('./uptrade/icons/heart-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-videocamera-slash {
  background-image: url('./uptrade/icons/videocamera-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-babycarriage {
  background-image: url('./uptrade/icons/babycarriage.svg');
  width: 16px;
  height: 16px;
}
.bi-image {
  background-image: url('./uptrade/icons/image.svg');
  width: 16px;
  height: 16px;
}
.bi-lifebuoy {
  background-image: url('./uptrade/icons/lifebuoy.svg');
  width: 16px;
  height: 16px;
}
.bi-filmreel {
  background-image: url('./uptrade/icons/filmreel.svg');
  width: 16px;
  height: 16px;
}
.bi-list-ordered-3 {
  background-image: url('./uptrade/icons/list-ordered-3.svg');
  width: 16px;
  height: 16px;
}
.bi-vector {
  background-image: url('./uptrade/icons/vector.svg');
  width: 16px;
  height: 16px;
}
.bi-history {
  background-image: url('./uptrade/icons/history.svg');
  width: 16px;
  height: 16px;
}
.bi-signout {
  background-image: url('./uptrade/icons/signout.svg');
  width: 16px;
  height: 16px;
}
.bi-grid {
  background-image: url('./uptrade/icons/grid.svg');
  width: 16px;
  height: 16px;
}
.bi-tag {
  background-image: url('./uptrade/icons/tag.svg');
  width: 16px;
  height: 16px;
}
.bi-book-open {
  background-image: url('./uptrade/icons/book-open.svg');
  width: 16px;
  height: 16px;
}
.bi-globe {
  background-image: url('./uptrade/icons/globe.svg');
  width: 16px;
  height: 16px;
}
.bi-stopwatch {
  background-image: url('./uptrade/icons/stopwatch.svg');
  width: 16px;
  height: 16px;
}
.bi-bulb {
  background-image: url('./uptrade/icons/bulb.svg');
  width: 16px;
  height: 16px;
}
.bi-pin-2 {
  background-image: url('./uptrade/icons/pin-2.svg');
  width: 16px;
  height: 16px;
}
.bi-elements-2 {
  background-image: url('./uptrade/icons/elements-2.svg');
  width: 16px;
  height: 16px;
}
.bi-eye {
  background-image: url('./uptrade/icons/eye.svg');
  width: 16px;
  height: 16px;
}
.bi-align-center {
  background-image: url('./uptrade/icons/align-center.svg');
  width: 16px;
  height: 16px;
}
.bi-creditcard-scan {
  background-image: url('./uptrade/icons/creditcard-scan.svg');
  width: 16px;
  height: 16px;
}
.bi-cloud-1 {
  background-image: url('./uptrade/icons/cloud-1.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-music {
  background-image: url('./uptrade/icons/folder-music.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-2-plus {
  background-image: url('./uptrade/icons/comment-2-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-monitor {
  background-image: url('./uptrade/icons/monitor.svg');
  width: 16px;
  height: 16px;
}
.bi-creditcard-income {
  background-image: url('./uptrade/icons/creditcard-income.svg');
  width: 16px;
  height: 16px;
}
.bi-hashtag-circle {
  background-image: url('./uptrade/icons/hashtag-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-maximize2 {
  background-image: url('./uptrade/icons/maximize2.svg');
  width: 16px;
  height: 16px;
}
.bi-wallet-plus {
  background-image: url('./uptrade/icons/wallet-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-forwardall {
  background-image: url('./uptrade/icons/forwardall.svg');
  width: 16px;
  height: 16px;
}
.bi-file-text {
  background-image: url('./uptrade/icons/file-text.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-16 {
  background-image: url('./uptrade/icons/grid-16.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-bottomright-circle {
  background-image: url('./uptrade/icons/arrow-bottomright-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-booklibrary {
  background-image: url('./uptrade/icons/booklibrary.svg');
  width: 16px;
  height: 16px;
}
.bi-alignbottom {
  background-image: url('./uptrade/icons/alignbottom.svg');
  width: 16px;
  height: 16px;
}
.bi-list-unordered-5 {
  background-image: url('./uptrade/icons/list-unordered-5.svg');
  width: 16px;
  height: 16px;
}
.bi-playlist-plus {
  background-image: url('./uptrade/icons/playlist-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-pin-3 {
  background-image: url('./uptrade/icons/pin-3.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-11 {
  background-image: url('./uptrade/icons/grid-11.svg');
  width: 16px;
  height: 16px;
}
.bi-radio {
  background-image: url('./uptrade/icons/radio.svg');
  width: 16px;
  height: 16px;
}
.bi-heart {
  background-image: url('./uptrade/icons/heart.svg');
  width: 16px;
  height: 16px;
}
.bi-forward-5 {
  background-image: url('./uptrade/icons/forward-5.svg');
  width: 16px;
  height: 16px;
}
.bi-border-left {
  background-image: url('./uptrade/icons/border-left.svg');
  width: 16px;
  height: 16px;
}
.bi-at {
  background-image: url('./uptrade/icons/at.svg');
  width: 16px;
  height: 16px;
}
.bi-maximize-2 {
  background-image: url('./uptrade/icons/maximize-2.svg');
  width: 16px;
  height: 16px;
}
.bi-call-calling {
  background-image: url('./uptrade/icons/call-calling.svg');
  width: 16px;
  height: 16px;
}
.bi-list-ordered {
  background-image: url('./uptrade/icons/list-ordered.svg');
  width: 16px;
  height: 16px;
}
.bi-calculator {
  background-image: url('./uptrade/icons/calculator.svg');
  width: 16px;
  height: 16px;
}
.bi-align-right-2 {
  background-image: url('./uptrade/icons/align-right-2.svg');
  width: 16px;
  height: 16px;
}
.bi-bookqueue {
  background-image: url('./uptrade/icons/bookqueue.svg');
  width: 16px;
  height: 16px;
}
.bi-call-redirected {
  background-image: url('./uptrade/icons/call-redirected.svg');
  width: 16px;
  height: 16px;
}
.bi-selection {
  background-image: url('./uptrade/icons/selection.svg');
  width: 16px;
  height: 16px;
}
.bi-battery-charging {
  background-image: url('./uptrade/icons/battery-charging.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-08 {
  background-image: url('./uptrade/icons/grid-08.svg');
  width: 16px;
  height: 16px;
}
.bi-sun {
  background-image: url('./uptrade/icons/sun.svg');
  width: 16px;
  height: 16px;
}
.bi-undo-2 {
  background-image: url('./uptrade/icons/undo-2.svg');
  width: 16px;
  height: 16px;
}
.bi-cash {
  background-image: url('./uptrade/icons/cash.svg');
  width: 16px;
  height: 16px;
}
.bi-thumbsdown {
  background-image: url('./uptrade/icons/thumbsdown.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-minus {
  background-image: url('./uptrade/icons/comment-minus.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-2 {
  background-image: url('./uptrade/icons/comment-2.svg');
  width: 16px;
  height: 16px;
}
.bi-headphones-2 {
  background-image: url('./uptrade/icons/headphones-2.svg');
  width: 16px;
  height: 16px;
}
.bi-playlist {
  background-image: url('./uptrade/icons/playlist.svg');
  width: 16px;
  height: 16px;
}
.bi-briefcase {
  background-image: url('./uptrade/icons/briefcase.svg');
  width: 16px;
  height: 16px;
}
.bi-clock-zzz {
  background-image: url('./uptrade/icons/clock-zzz.svg');
  width: 16px;
  height: 16px;
}
.bi-file-code {
  background-image: url('./uptrade/icons/file-code.svg');
  width: 16px;
  height: 16px;
}
.bi-comments-2 {
  background-image: url('./uptrade/icons/comments-2.svg');
  width: 16px;
  height: 16px;
}
.bi-alert-triangle {
  background-image: url('./uptrade/icons/alert-triangle.svg');
  width: 16px;
  height: 16px;
}
.bi-trophy {
  background-image: url('./uptrade/icons/trophy.svg');
  width: 16px;
  height: 16px;
}
.bi-keyboard {
  background-image: url('./uptrade/icons/keyboard.svg');
  width: 16px;
  height: 16px;
}
.bi-replay-5 {
  background-image: url('./uptrade/icons/replay-5.svg');
  width: 16px;
  height: 16px;
}
.bi-image-cross {
  background-image: url('./uptrade/icons/image-cross.svg');
  width: 16px;
  height: 16px;
}
.bi-wheelchair {
  background-image: url('./uptrade/icons/wheelchair.svg');
  width: 16px;
  height: 16px;
}
.bi-volume-high {
  background-image: url('./uptrade/icons/volume-high.svg');
  width: 16px;
  height: 16px;
}
.bi-text {
  background-image: url('./uptrade/icons/text.svg');
  width: 16px;
  height: 16px;
}
.bi-border-outer {
  background-image: url('./uptrade/icons/border-outer.svg');
  width: 16px;
  height: 16px;
}
.bi-timer {
  background-image: url('./uptrade/icons/timer.svg');
  width: 16px;
  height: 16px;
}
.bi-border-clear {
  background-image: url('./uptrade/icons/border-clear.svg');
  width: 16px;
  height: 16px;
}
.bi-captions-1 {
  background-image: url('./uptrade/icons/captions-1.svg');
  width: 16px;
  height: 16px;
}
.bi-painttool {
  background-image: url('./uptrade/icons/painttool.svg');
  width: 16px;
  height: 16px;
}
.bi-procent-circle {
  background-image: url('./uptrade/icons/procent-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-loader-2 {
  background-image: url('./uptrade/icons/loader-2.svg');
  width: 16px;
  height: 16px;
}
.bi-elements-3-1 {
  background-image: url('./uptrade/icons/elements-3-1.svg');
  width: 16px;
  height: 16px;
}
.bi-list-unordered-4 {
  background-image: url('./uptrade/icons/list-unordered-4.svg');
  width: 16px;
  height: 16px;
}
.bi-elements-plus {
  background-image: url('./uptrade/icons/elements-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-pin-minus {
  background-image: url('./uptrade/icons/pin-minus.svg');
  width: 16px;
  height: 16px;
}
.bi-musicnote-2 {
  background-image: url('./uptrade/icons/musicnote-2.svg');
  width: 16px;
  height: 16px;
}
.bi-battery-2 {
  background-image: url('./uptrade/icons/battery-2.svg');
  width: 16px;
  height: 16px;
}
.bi-cloud-cross {
  background-image: url('./uptrade/icons/cloud-cross.svg');
  width: 16px;
  height: 16px;
}
.bi-image-download {
  background-image: url('./uptrade/icons/image-download.svg');
  width: 16px;
  height: 16px;
}
.bi-frame {
  background-image: url('./uptrade/icons/frame.svg');
  width: 16px;
  height: 16px;
}
.bi-shuffle {
  background-image: url('./uptrade/icons/shuffle.svg');
  width: 16px;
  height: 16px;
}
.bi-align-center-2 {
  background-image: url('./uptrade/icons/align-center-2.svg');
  width: 16px;
  height: 16px;
}
.bi-toggle-off {
  background-image: url('./uptrade/icons/toggle-off.svg');
  width: 16px;
  height: 16px;
}
.bi-battery {
  background-image: url('./uptrade/icons/battery.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-03 {
  background-image: url('./uptrade/icons/grid-03.svg');
  width: 16px;
  height: 16px;
}
.bi-send-2 {
  background-image: url('./uptrade/icons/send-2.svg');
  width: 16px;
  height: 16px;
}
.bi-code {
  background-image: url('./uptrade/icons/code.svg');
  width: 16px;
  height: 16px;
}
.bi-bookmark {
  background-image: url('./uptrade/icons/bookmark.svg');
  width: 16px;
  height: 16px;
}
.bi-clock-slash {
  background-image: url('./uptrade/icons/clock-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-bug {
  background-image: url('./uptrade/icons/bug.svg');
  width: 16px;
  height: 16px;
}
.bi-user {
  background-image: url('./uptrade/icons/user.svg');
  width: 16px;
  height: 16px;
}
.bi-sliders-horiz-2 {
  background-image: url('./uptrade/icons/sliders-horiz-2.svg');
  width: 16px;
  height: 16px;
}
.bi-textformat {
  background-image: url('./uptrade/icons/textformat.svg');
  width: 16px;
  height: 16px;
}
.bi-text-italic {
  background-image: url('./uptrade/icons/text-italic.svg');
  width: 16px;
  height: 16px;
}
.bi-elements-3 {
  background-image: url('./uptrade/icons/elements-3.svg');
  width: 16px;
  height: 16px;
}
.bi-archive {
  background-image: url('./uptrade/icons/archive.svg');
  width: 16px;
  height: 16px;
}
.bi-paperclip {
  background-image: url('./uptrade/icons/paperclip.svg');
  width: 16px;
  height: 16px;
}
.bi-elements {
  background-image: url('./uptrade/icons/elements.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-check {
  background-image: url('./uptrade/icons/folder-check.svg');
  width: 16px;
  height: 16px;
}
.bi-route {
  background-image: url('./uptrade/icons/route.svg');
  width: 16px;
  height: 16px;
}
.bi-refresh-ccw-alert {
  background-image: url('./uptrade/icons/refresh-ccw-alert.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-corner-ccw-lb {
  background-image: url('./uptrade/icons/arrow-corner-ccw-lb.svg');
  width: 16px;
  height: 16px;
}
.bi-image-3 {
  background-image: url('./uptrade/icons/image-3.svg');
  width: 16px;
  height: 16px;
}
.bi-file-cross {
  background-image: url('./uptrade/icons/file-cross.svg');
  width: 16px;
  height: 16px;
}
.bi-procent {
  background-image: url('./uptrade/icons/procent.svg');
  width: 16px;
  height: 16px;
}
.bi-import {
  background-image: url('./uptrade/icons/import.svg');
  width: 16px;
  height: 16px;
}
.bi-award {
  background-image: url('./uptrade/icons/award.svg');
  width: 16px;
  height: 16px;
}
.bi-align-left {
  background-image: url('./uptrade/icons/align-left.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-bottomleft-circle {
  background-image: url('./uptrade/icons/arrow-bottomleft-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-sliders-vert-2 {
  background-image: url('./uptrade/icons/sliders-vert-2.svg');
  width: 16px;
  height: 16px;
}
.bi-trend-down {
  background-image: url('./uptrade/icons/trend-down.svg');
  width: 16px;
  height: 16px;
}
.bi-phone {
  background-image: url('./uptrade/icons/phone.svg');
  width: 16px;
  height: 16px;
}
.bi-align-top {
  background-image: url('./uptrade/icons/align-top.svg');
  width: 16px;
  height: 16px;
}
.bi-edit-3 {
  background-image: url('./uptrade/icons/edit-3.svg');
  width: 16px;
  height: 16px;
}
.bi-bed-1 {
  background-image: url('./uptrade/icons/bed-1.svg');
  width: 16px;
  height: 16px;
}
.bi-repeat-on {
  background-image: url('./uptrade/icons/repeat-on.svg');
  width: 16px;
  height: 16px;
}
.bi-letterspacing {
  background-image: url('./uptrade/icons/letterspacing.svg');
  width: 16px;
  height: 16px;
}
.bi-processor {
  background-image: url('./uptrade/icons/processor.svg');
  width: 16px;
  height: 16px;
}
.bi-user-circle {
  background-image: url('./uptrade/icons/user-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-call-remove {
  background-image: url('./uptrade/icons/call-remove.svg');
  width: 16px;
  height: 16px;
}
.bi-smallcaps {
  background-image: url('./uptrade/icons/smallcaps.svg');
  width: 16px;
  height: 16px;
}
.bi-file-move {
  background-image: url('./uptrade/icons/file-move.svg');
  width: 16px;
  height: 16px;
}
.bi-sort-number {
  background-image: url('./uptrade/icons/sort-number.svg');
  width: 16px;
  height: 16px;
}
.bi-cd {
  background-image: url('./uptrade/icons/cd.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-compressed {
  background-image: url('./uptrade/icons/folder-compressed.svg');
  width: 16px;
  height: 16px;
}
.bi-bookmark-minus {
  background-image: url('./uptrade/icons/bookmark-minus.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-right-curved-circle {
  background-image: url('./uptrade/icons/arrow-right-curved-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-wrench {
  background-image: url('./uptrade/icons/wrench.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-04 {
  background-image: url('./uptrade/icons/grid-04.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-left-circle {
  background-image: url('./uptrade/icons/arrow-left-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-sticker {
  background-image: url('./uptrade/icons/sticker.svg');
  width: 16px;
  height: 16px;
}
.bi-playlist-play {
  background-image: url('./uptrade/icons/playlist-play.svg');
  width: 16px;
  height: 16px;
}
.bi-paperclip-2-slash {
  background-image: url('./uptrade/icons/paperclip-2-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-musicnote {
  background-image: url('./uptrade/icons/musicnote.svg');
  width: 16px;
  height: 16px;
}
.bi-direction-2 {
  background-image: url('./uptrade/icons/direction-2.svg');
  width: 16px;
  height: 16px;
}
.bi-maximize {
  background-image: url('./uptrade/icons/maximize.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-minus {
  background-image: url('./uptrade/icons/folder-minus.svg');
  width: 16px;
  height: 16px;
}
.bi-elements-4 {
  background-image: url('./uptrade/icons/elements-4.svg');
  width: 16px;
  height: 16px;
}
.bi-creditcard-minus {
  background-image: url('./uptrade/icons/creditcard-minus.svg');
  width: 16px;
  height: 16px;
}
.bi-note {
  background-image: url('./uptrade/icons/note.svg');
  width: 16px;
  height: 16px;
}
.bi-minimize {
  background-image: url('./uptrade/icons/minimize.svg');
  width: 16px;
  height: 16px;
}
.bi-image-edit {
  background-image: url('./uptrade/icons/image-edit.svg');
  width: 16px;
  height: 16px;
}
.bi-chevrons-down {
  background-image: url('./uptrade/icons/chevrons-down.svg');
  width: 16px;
  height: 16px;
}
.bi-star-slash {
  background-image: url('./uptrade/icons/star-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-presentation-chart {
  background-image: url('./uptrade/icons/presentation-chart.svg');
  width: 16px;
  height: 16px;
}
.bi-sunrise {
  background-image: url('./uptrade/icons/sunrise.svg');
  width: 16px;
  height: 16px;
}
.bi-box {
  background-image: url('./uptrade/icons/box.svg');
  width: 16px;
  height: 16px;
}
.bi-file-invoice {
  background-image: url('./uptrade/icons/file-invoice.svg');
  width: 16px;
  height: 16px;
}
.bi-smallcaps-2 {
  background-image: url('./uptrade/icons/smallcaps-2.svg');
  width: 16px;
  height: 16px;
}
.bi-align-horizontally {
  background-image: url('./uptrade/icons/align-horizontally.svg');
  width: 16px;
  height: 16px;
}
.bi-activity-2 {
  background-image: url('./uptrade/icons/activity-2.svg');
  width: 16px;
  height: 16px;
}
.bi-devices {
  background-image: url('./uptrade/icons/devices.svg');
  width: 16px;
  height: 16px;
}
.bi-phone2 {
  background-image: url('./uptrade/icons/phone2.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-2-alert {
  background-image: url('./uptrade/icons/comment-2-alert.svg');
  width: 16px;
  height: 16px;
}
.bi-shoppingbag {
  background-image: url('./uptrade/icons/shoppingbag.svg');
  width: 16px;
  height: 16px;
}
.bi-tablet {
  background-image: url('./uptrade/icons/tablet.svg');
  width: 16px;
  height: 16px;
}
.bi-maximize-1 {
  background-image: url('./uptrade/icons/maximize-1.svg');
  width: 16px;
  height: 16px;
}
.bi-bluetooth {
  background-image: url('./uptrade/icons/bluetooth.svg');
  width: 16px;
  height: 16px;
}
.bi-call-outgoing {
  background-image: url('./uptrade/icons/call-outgoing.svg');
  width: 16px;
  height: 16px;
}
.bi-firstline-1 {
  background-image: url('./uptrade/icons/firstline-1.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-photo {
  background-image: url('./uptrade/icons/folder-photo.svg');
  width: 16px;
  height: 16px;
}
.bi-paperclip-slash {
  background-image: url('./uptrade/icons/paperclip-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-barchart {
  background-image: url('./uptrade/icons/barchart.svg');
  width: 16px;
  height: 16px;
}
.bi-specialcharachers {
  background-image: url('./uptrade/icons/specialcharachers.svg');
  width: 16px;
  height: 16px;
}
.bi-weighter-2 {
  background-image: url('./uptrade/icons/weighter-2.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-right {
  background-image: url('./uptrade/icons/arrow-right.svg');
  width: 16px;
  height: 16px;
}
.bi-alert-circle {
  background-image: url('./uptrade/icons/alert-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-calendar-dates {
  background-image: url('./uptrade/icons/calendar-dates.svg');
  width: 16px;
  height: 16px;
}
.bi-replay-10 {
  background-image: url('./uptrade/icons/replay-10.svg');
  width: 16px;
  height: 16px;
}
.bi-fen {
  background-image: url('./uptrade/icons/fen.svg');
  width: 16px;
  height: 16px;
}
.bi-rotate-ccw {
  background-image: url('./uptrade/icons/rotate-ccw.svg');
  width: 16px;
  height: 16px;
}
.bi-aligntop {
  background-image: url('./uptrade/icons/aligntop.svg');
  width: 16px;
  height: 16px;
}
.bi-file-check {
  background-image: url('./uptrade/icons/file-check.svg');
  width: 16px;
  height: 16px;
}
.bi-chevron-right {
  background-image: url('./uptrade/icons/chevron-right.svg');
  width: 16px;
  height: 16px;
}
.bi-emotion-wow {
  background-image: url('./uptrade/icons/emotion-wow.svg');
  width: 16px;
  height: 16px;
}
.bi-scale {
  background-image: url('./uptrade/icons/scale.svg');
  width: 16px;
  height: 16px;
}
.bi-ruler {
  background-image: url('./uptrade/icons/ruler.svg');
  width: 16px;
  height: 16px;
}
.bi-signin {
  background-image: url('./uptrade/icons/signin.svg');
  width: 16px;
  height: 16px;
}
.bi-user-cross {
  background-image: url('./uptrade/icons/user-cross.svg');
  width: 16px;
  height: 16px;
}
.bi-speed {
  background-image: url('./uptrade/icons/speed.svg');
  width: 16px;
  height: 16px;
}
.bi-watch {
  background-image: url('./uptrade/icons/watch.svg');
  width: 16px;
  height: 16px;
}
.bi-export-3 {
  background-image: url('./uptrade/icons/export-3.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-09 {
  background-image: url('./uptrade/icons/grid-09.svg');
  width: 16px;
  height: 16px;
}
.bi-clock-minus {
  background-image: url('./uptrade/icons/clock-minus.svg');
  width: 16px;
  height: 16px;
}
.bi-copy {
  background-image: url('./uptrade/icons/copy.svg');
  width: 16px;
  height: 16px;
}
.bi-call-slash {
  background-image: url('./uptrade/icons/call-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-replay-15 {
  background-image: url('./uptrade/icons/replay-15.svg');
  width: 16px;
  height: 16px;
}
.bi-call-received {
  background-image: url('./uptrade/icons/call-received.svg');
  width: 16px;
  height: 16px;
}
.bi-mic-slash {
  background-image: url('./uptrade/icons/mic-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-file-slash {
  background-image: url('./uptrade/icons/file-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-filter {
  background-image: url('./uptrade/icons/filter.svg');
  width: 16px;
  height: 16px;
}
.bi-check {
  background-image: url('./uptrade/icons/check.svg');
  width: 16px;
  height: 16px;
}
.bi-arrows-move {
  background-image: url('./uptrade/icons/arrows-move.svg');
  width: 16px;
  height: 16px;
}
.bi-mapview {
  background-image: url('./uptrade/icons/mapview.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-corner-cw-rb {
  background-image: url('./uptrade/icons/arrow-corner-cw-rb.svg');
  width: 16px;
  height: 16px;
}
.bi-clock {
  background-image: url('./uptrade/icons/clock.svg');
  width: 16px;
  height: 16px;
}
.bi-pin {
  background-image: url('./uptrade/icons/pin.svg');
  width: 16px;
  height: 16px;
}
.bi-calendar-minus {
  background-image: url('./uptrade/icons/calendar-minus.svg');
  width: 16px;
  height: 16px;
}
.bi-play-previous {
  background-image: url('./uptrade/icons/play-previous.svg');
  width: 16px;
  height: 16px;
}
.bi-bookmark-slash {
  background-image: url('./uptrade/icons/bookmark-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-export2 {
  background-image: url('./uptrade/icons/export2.svg');
  width: 16px;
  height: 16px;
}
.bi-activity {
  background-image: url('./uptrade/icons/activity.svg');
  width: 16px;
  height: 16px;
}
.bi-edit-2 {
  background-image: url('./uptrade/icons/edit-2.svg');
  width: 16px;
  height: 16px;
}
.bi-yinyang {
  background-image: url('./uptrade/icons/yinyang.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-cloud {
  background-image: url('./uptrade/icons/folder-cloud.svg');
  width: 16px;
  height: 16px;
}
.bi-file-scan {
  background-image: url('./uptrade/icons/file-scan.svg');
  width: 16px;
  height: 16px;
}
.bi-key {
  background-image: url('./uptrade/icons/key.svg');
  width: 16px;
  height: 16px;
}
.bi-trash-2 {
  background-image: url('./uptrade/icons/trash-2.svg');
  width: 16px;
  height: 16px;
}
.bi-book {
  background-image: url('./uptrade/icons/book.svg');
  width: 16px;
  height: 16px;
}
.bi-stack {
  background-image: url('./uptrade/icons/stack.svg');
  width: 16px;
  height: 16px;
}
.bi-login {
  background-image: url('./uptrade/icons/login.svg');
  width: 16px;
  height: 16px;
}
.bi-dashboard-2 {
  background-image: url('./uptrade/icons/dashboard-2.svg');
  width: 16px;
  height: 16px;
}
.bi-inbox-in {
  background-image: url('./uptrade/icons/inbox-in.svg');
  width: 16px;
  height: 16px;
}
.bi-captions {
  background-image: url('./uptrade/icons/captions.svg');
  width: 16px;
  height: 16px;
}
.bi-chevrons-right {
  background-image: url('./uptrade/icons/chevrons-right.svg');
  width: 16px;
  height: 16px;
}
.bi-minus-circle {
  background-image: url('./uptrade/icons/minus-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-brush {
  background-image: url('./uptrade/icons/brush.svg');
  width: 16px;
  height: 16px;
}
.bi-fastrewind {
  background-image: url('./uptrade/icons/fastrewind.svg');
  width: 16px;
  height: 16px;
}
.bi-border-top {
  background-image: url('./uptrade/icons/border-top.svg');
  width: 16px;
  height: 16px;
}
.bi-pause {
  background-image: url('./uptrade/icons/pause.svg');
  width: 16px;
  height: 16px;
}
.bi-thermometer {
  background-image: url('./uptrade/icons/thermometer.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-topright-circle {
  background-image: url('./uptrade/icons/arrow-topright-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-kanban {
  background-image: url('./uptrade/icons/kanban.svg');
  width: 16px;
  height: 16px;
}
.bi-note-2 {
  background-image: url('./uptrade/icons/note-2.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-corner-ccw-rb {
  background-image: url('./uptrade/icons/arrow-corner-ccw-rb.svg');
  width: 16px;
  height: 16px;
}
.bi-firstline-3 {
  background-image: url('./uptrade/icons/firstline-3.svg');
  width: 16px;
  height: 16px;
}
.bi-videoqueue {
  background-image: url('./uptrade/icons/videoqueue.svg');
  width: 16px;
  height: 16px;
}
.bi-trash {
  background-image: url('./uptrade/icons/trash.svg');
  width: 16px;
  height: 16px;
}
.bi-file {
  background-image: url('./uptrade/icons/file.svg');
  width: 16px;
  height: 16px;
}
.bi-list-unordered-3 {
  background-image: url('./uptrade/icons/list-unordered-3.svg');
  width: 16px;
  height: 16px;
}
.bi-sort-number-reverse {
  background-image: url('./uptrade/icons/sort-number-reverse.svg');
  width: 16px;
  height: 16px;
}
.bi-bell-plus {
  background-image: url('./uptrade/icons/bell-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-2-check {
  background-image: url('./uptrade/icons/comment-2-check.svg');
  width: 16px;
  height: 16px;
}
.bi-fatrows {
  background-image: url('./uptrade/icons/fatrows.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-topright {
  background-image: url('./uptrade/icons/arrow-topright.svg');
  width: 16px;
  height: 16px;
}
.bi-user2 {
  background-image: url('./uptrade/icons/user2.svg');
  width: 16px;
  height: 16px;
}
.bi-folder {
  background-image: url('./uptrade/icons/folder.svg');
  width: 16px;
  height: 16px;
}
.bi-bell-slash {
  background-image: url('./uptrade/icons/bell-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-battery-slash {
  background-image: url('./uptrade/icons/battery-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-megaphone {
  background-image: url('./uptrade/icons/megaphone.svg');
  width: 16px;
  height: 16px;
}
.bi-plus-circle {
  background-image: url('./uptrade/icons/plus-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-camera {
  background-image: url('./uptrade/icons/camera.svg');
  width: 16px;
  height: 16px;
}
.bi-stop {
  background-image: url('./uptrade/icons/stop.svg');
  width: 16px;
  height: 16px;
}
.bi-check-circle {
  background-image: url('./uptrade/icons/check-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-upload {
  background-image: url('./uptrade/icons/folder-upload.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-02 {
  background-image: url('./uptrade/icons/grid-02.svg');
  width: 16px;
  height: 16px;
}
.bi-musicnote-3 {
  background-image: url('./uptrade/icons/musicnote-3.svg');
  width: 16px;
  height: 16px;
}
.bi-align-justify-2 {
  background-image: url('./uptrade/icons/align-justify-2.svg');
  width: 16px;
  height: 16px;
}
.bi-call-incoming {
  background-image: url('./uptrade/icons/call-incoming.svg');
  width: 16px;
  height: 16px;
}
.bi-nut {
  background-image: url('./uptrade/icons/nut.svg');
  width: 16px;
  height: 16px;
}
.bi-file-text2 {
  background-image: url('./uptrade/icons/file-text2.svg');
  width: 16px;
  height: 16px;
}
.bi-play {
  background-image: url('./uptrade/icons/play.svg');
  width: 16px;
  height: 16px;
}
.bi-laptop {
  background-image: url('./uptrade/icons/laptop.svg');
  width: 16px;
  height: 16px;
}
.bi-volume-slash {
  background-image: url('./uptrade/icons/volume-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-script {
  background-image: url('./uptrade/icons/script.svg');
  width: 16px;
  height: 16px;
}
.bi-fastforward {
  background-image: url('./uptrade/icons/fastforward.svg');
  width: 16px;
  height: 16px;
}
.bi-toggle-on {
  background-image: url('./uptrade/icons/toggle-on.svg');
  width: 16px;
  height: 16px;
}
.bi-truck {
  background-image: url('./uptrade/icons/truck.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-move {
  background-image: url('./uptrade/icons/folder-move.svg');
  width: 16px;
  height: 16px;
}
.bi-list-unordered {
  background-image: url('./uptrade/icons/list-unordered.svg');
  width: 16px;
  height: 16px;
}
.bi-question {
  background-image: url('./uptrade/icons/question.svg');
  width: 16px;
  height: 16px;
}
.bi-swatchbook {
  background-image: url('./uptrade/icons/swatchbook.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-cancel {
  background-image: url('./uptrade/icons/comment-cancel.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-topleft {
  background-image: url('./uptrade/icons/arrow-topleft.svg');
  width: 16px;
  height: 16px;
}
.bi-chart-column {
  background-image: url('./uptrade/icons/chart-column.svg');
  width: 16px;
  height: 16px;
}
.bi-shawer {
  background-image: url('./uptrade/icons/shawer.svg');
  width: 16px;
  height: 16px;
}
.bi-checkbox-circle {
  background-image: url('./uptrade/icons/checkbox-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-firstline-2 {
  background-image: url('./uptrade/icons/firstline-2.svg');
  width: 16px;
  height: 16px;
}
.bi-trend-up {
  background-image: url('./uptrade/icons/trend-up.svg');
  width: 16px;
  height: 16px;
}
.bi-moon {
  background-image: url('./uptrade/icons/moon.svg');
  width: 16px;
  height: 16px;
}
.bi-user-check {
  background-image: url('./uptrade/icons/user-check.svg');
  width: 16px;
  height: 16px;
}
.bi-lock-2-opened {
  background-image: url('./uptrade/icons/lock-2-opened.svg');
  width: 16px;
  height: 16px;
}
.bi-shield {
  background-image: url('./uptrade/icons/shield.svg');
  width: 16px;
  height: 16px;
}
.bi-text-bold {
  background-image: url('./uptrade/icons/text-bold.svg');
  width: 16px;
  height: 16px;
}
.bi-grid-14 {
  background-image: url('./uptrade/icons/grid-14.svg');
  width: 16px;
  height: 16px;
}
.bi-text-slash-2 {
  background-image: url('./uptrade/icons/text-slash-2.svg');
  width: 16px;
  height: 16px;
}
.bi-pharagraphspacing {
  background-image: url('./uptrade/icons/pharagraphspacing.svg');
  width: 16px;
  height: 16px;
}
.bi-email {
  background-image: url('./uptrade/icons/email.svg');
  width: 16px;
  height: 16px;
}
.bi-server {
  background-image: url('./uptrade/icons/server.svg');
  width: 16px;
  height: 16px;
}
.bi-glasses-vr {
  background-image: url('./uptrade/icons/glasses-vr.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-topleft-circle {
  background-image: url('./uptrade/icons/arrow-topleft-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-weighter {
  background-image: url('./uptrade/icons/weighter.svg');
  width: 16px;
  height: 16px;
}
.bi-ticket {
  background-image: url('./uptrade/icons/ticket.svg');
  width: 16px;
  height: 16px;
}
.bi-filmtape {
  background-image: url('./uptrade/icons/filmtape.svg');
  width: 16px;
  height: 16px;
}
.bi-padding {
  background-image: url('./uptrade/icons/padding.svg');
  width: 16px;
  height: 16px;
}
.bi-record {
  background-image: url('./uptrade/icons/record.svg');
  width: 16px;
  height: 16px;
}
.bi-creditcard-slash {
  background-image: url('./uptrade/icons/creditcard-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-download {
  background-image: url('./uptrade/icons/folder-download.svg');
  width: 16px;
  height: 16px;
}
.bi-bell-check {
  background-image: url('./uptrade/icons/bell-check.svg');
  width: 16px;
  height: 16px;
}
.bi-degrees360 {
  background-image: url('./uptrade/icons/degrees360.svg');
  width: 16px;
  height: 16px;
}
.bi-harddrive {
  background-image: url('./uptrade/icons/harddrive.svg');
  width: 16px;
  height: 16px;
}
.bi-paintbucket {
  background-image: url('./uptrade/icons/paintbucket.svg');
  width: 16px;
  height: 16px;
}
.bi-bed {
  background-image: url('./uptrade/icons/bed.svg');
  width: 16px;
  height: 16px;
}
.bi-image-plus {
  background-image: url('./uptrade/icons/image-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-home {
  background-image: url('./uptrade/icons/home.svg');
  width: 16px;
  height: 16px;
}
.bi-broadcast {
  background-image: url('./uptrade/icons/broadcast.svg');
  width: 16px;
  height: 16px;
}
.bi-redo {
  background-image: url('./uptrade/icons/redo.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-22 {
  background-image: url('./uptrade/icons/comment-22.svg');
  width: 16px;
  height: 16px;
}
.bi-box-2 {
  background-image: url('./uptrade/icons/box-2.svg');
  width: 16px;
  height: 16px;
}
.bi-rotate-cw {
  background-image: url('./uptrade/icons/rotate-cw.svg');
  width: 16px;
  height: 16px;
}
.bi-presentation-line {
  background-image: url('./uptrade/icons/presentation-line.svg');
  width: 16px;
  height: 16px;
}
.bi-lock-slash {
  background-image: url('./uptrade/icons/lock-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-plug-slash {
  background-image: url('./uptrade/icons/plug-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-minus {
  background-image: url('./uptrade/icons/minus.svg');
  width: 16px;
  height: 16px;
}
.bi-stopwatch-slash {
  background-image: url('./uptrade/icons/stopwatch-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-sort-text-reverse {
  background-image: url('./uptrade/icons/sort-text-reverse.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-top-circle {
  background-image: url('./uptrade/icons/arrow-top-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-edit-slash {
  background-image: url('./uptrade/icons/edit-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-musiclibrary {
  background-image: url('./uptrade/icons/musiclibrary.svg');
  width: 16px;
  height: 16px;
}
.bi-cloud-lightning {
  background-image: url('./uptrade/icons/cloud-lightning.svg');
  width: 16px;
  height: 16px;
}
.bi-rocket-2 {
  background-image: url('./uptrade/icons/rocket-2.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-open2 {
  background-image: url('./uptrade/icons/folder-open2.svg');
  width: 16px;
  height: 16px;
}
.bi-power {
  background-image: url('./uptrade/icons/power.svg');
  width: 16px;
  height: 16px;
}
.bi-globe-2 {
  background-image: url('./uptrade/icons/globe-2.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-corner-cw-rt {
  background-image: url('./uptrade/icons/arrow-corner-cw-rt.svg');
  width: 16px;
  height: 16px;
}
.bi-pin-cross {
  background-image: url('./uptrade/icons/pin-cross.svg');
  width: 16px;
  height: 16px;
}
.bi-indent-firstline {
  background-image: url('./uptrade/icons/indent-firstline.svg');
  width: 16px;
  height: 16px;
}
.bi-text-strikethrough {
  background-image: url('./uptrade/icons/text-strikethrough.svg');
  width: 16px;
  height: 16px;
}
.bi-import-2 {
  background-image: url('./uptrade/icons/import-2.svg');
  width: 16px;
  height: 16px;
}
.bi-slash {
  background-image: url('./uptrade/icons/slash.svg');
  width: 16px;
  height: 16px;
}
.bi-indent-decrease {
  background-image: url('./uptrade/icons/indent-decrease.svg');
  width: 16px;
  height: 16px;
}
.bi-lock-2 {
  background-image: url('./uptrade/icons/lock-2.svg');
  width: 16px;
  height: 16px;
}
.bi-flag-3 {
  background-image: url('./uptrade/icons/flag-3.svg');
  width: 16px;
  height: 16px;
}
.bi-notificationindicator {
  background-image: url('./uptrade/icons/notificationindicator.svg');
  width: 16px;
  height: 16px;
}
.bi-airplay {
  background-image: url('./uptrade/icons/airplay.svg');
  width: 16px;
  height: 16px;
}
.bi-weight {
  background-image: url('./uptrade/icons/weight.svg');
  width: 16px;
  height: 16px;
}
.bi-align-vertically {
  background-image: url('./uptrade/icons/align-vertically.svg');
  width: 16px;
  height: 16px;
}
.bi-wallet-move {
  background-image: url('./uptrade/icons/wallet-move.svg');
  width: 16px;
  height: 16px;
}
.bi-stop-circle {
  background-image: url('./uptrade/icons/stop-circle.svg');
  width: 16px;
  height: 16px;
}
.bi-quotes {
  background-image: url('./uptrade/icons/quotes.svg');
  width: 16px;
  height: 16px;
}
.bi-copyright {
  background-image: url('./uptrade/icons/copyright.svg');
  width: 16px;
  height: 16px;
}
.bi-invoice {
  background-image: url('./uptrade/icons/invoice.svg');
  width: 16px;
  height: 16px;
}
.bi-logout {
  background-image: url('./uptrade/icons/logout.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-2-slash {
  background-image: url('./uptrade/icons/comment-2-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-file-agreement {
  background-image: url('./uptrade/icons/file-agreement.svg');
  width: 16px;
  height: 16px;
}
.bi-tags-2 {
  background-image: url('./uptrade/icons/tags-2.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-2-question2 {
  background-image: url('./uptrade/icons/comment-2-question2.svg');
  width: 16px;
  height: 16px;
}
.bi-export {
  background-image: url('./uptrade/icons/export.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-plus {
  background-image: url('./uptrade/icons/comment-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-pin-plus {
  background-image: url('./uptrade/icons/pin-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-thumbsup {
  background-image: url('./uptrade/icons/thumbsup.svg');
  width: 16px;
  height: 16px;
}
.bi-align-right {
  background-image: url('./uptrade/icons/align-right.svg');
  width: 16px;
  height: 16px;
}
.bi-battery-1 {
  background-image: url('./uptrade/icons/battery-1.svg');
  width: 16px;
  height: 16px;
}
.bi-calendar-plus {
  background-image: url('./uptrade/icons/calendar-plus.svg');
  width: 16px;
  height: 16px;
}
.bi-pagebreak {
  background-image: url('./uptrade/icons/pagebreak.svg');
  width: 16px;
  height: 16px;
}
.bi-creditcard {
  background-image: url('./uptrade/icons/creditcard.svg');
  width: 16px;
  height: 16px;
}
.bi-pin-slash {
  background-image: url('./uptrade/icons/pin-slash.svg');
  width: 16px;
  height: 16px;
}
.bi-comments2 {
  background-image: url('./uptrade/icons/comments2.svg');
  width: 16px;
  height: 16px;
}
.bi-modules {
  background-image: url('./uptrade/icons/modules.svg');
  width: 16px;
  height: 16px;
}
.bi-framebounds {
  background-image: url('./uptrade/icons/framebounds.svg');
  width: 16px;
  height: 16px;
}
.bi-volume-cross {
  background-image: url('./uptrade/icons/volume-cross.svg');
  width: 16px;
  height: 16px;
}
.bi-receipt {
  background-image: url('./uptrade/icons/receipt.svg');
  width: 16px;
  height: 16px;
}
.bi-border-horizontal {
  background-image: url('./uptrade/icons/border-horizontal.svg');
  width: 16px;
  height: 16px;
}
.bi-call-onhold {
  background-image: url('./uptrade/icons/call-onhold.svg');
  width: 16px;
  height: 16px;
}
.bi-menu {
  background-image: url('./uptrade/icons/menu.svg');
  width: 16px;
  height: 16px;
}
.bi-comment-alert {
  background-image: url('./uptrade/icons/comment-alert.svg');
  width: 16px;
  height: 16px;
}
.bi-emotion-happy {
  background-image: url('./uptrade/icons/emotion-happy.svg');
  width: 16px;
  height: 16px;
}
.bi-inbox {
  background-image: url('./uptrade/icons/inbox.svg');
  width: 16px;
  height: 16px;
}
.bi-calendar-event {
  background-image: url('./uptrade/icons/calendar-event.svg');
  width: 16px;
  height: 16px;
}
.bi-direction {
  background-image: url('./uptrade/icons/direction.svg');
  width: 16px;
  height: 16px;
}
.bi-flashcard {
  background-image: url('./uptrade/icons/flashcard.svg');
  width: 16px;
  height: 16px;
}
.bi-user-arrowright {
  background-image: url('./uptrade/icons/user-arrowright.svg');
  width: 16px;
  height: 16px;
}
.bi-bed-2 {
  background-image: url('./uptrade/icons/bed-2.svg');
  width: 16px;
  height: 16px;
}
.bi-bookmarks {
  background-image: url('./uptrade/icons/bookmarks.svg');
  width: 16px;
  height: 16px;
}
.bi-arrow-bottomleft {
  background-image: url('./uptrade/icons/arrow-bottomleft.svg');
  width: 16px;
  height: 16px;
}
.bi-colorfilter {
  background-image: url('./uptrade/icons/colorfilter.svg');
  width: 16px;
  height: 16px;
}
.bi-memorycard {
  background-image: url('./uptrade/icons/memorycard.svg');
  width: 16px;
  height: 16px;
}
.bi-mic {
  background-image: url('./uptrade/icons/mic.svg');
  width: 16px;
  height: 16px;
}
.bi-folder-cross {
  background-image: url('./uptrade/icons/folder-cross.svg');
  width: 16px;
  height: 16px;
}
.bi-scan {
  background-image: url('./uptrade/icons/scan.svg');
  width: 16px;
  height: 16px;
}
.bi-watch-2 {
  background-image: url('./uptrade/icons/watch-2.svg');
  width: 16px;
  height: 16px;
}
.fa {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
