
$nav_btn_height: var(--sidebar-sublink-max-height);

.root-sidebar {
    position: fixed;
    top: 102px;
    bottom: 0px;
    left: -100%;
    z-index: 1003;
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    width: 0;

    @include media('>=xl') {
        border-right: 1px solid #E5E5E5;
        position: static;
        height: auto;
        overflow: auto;
        min-height: 100%;
        width: auto;
    }

    &_opened {
        @include media('<xl') {
            top: 102px;
            left: 0px;
            width: 100vw;
        }
        .bi-svg-cross {
            display: block !important;
        }
        .bi-svg-menu {
            display: none;
        }
    }

    .bi-svg-menu {
        transition: all 0.3s ease-in-out;
    }

    .bi-svg-cross {
        display: none;
    }

    &.minimized {
        .bi-svg-menu {
            transform: scaleX(-1);
        }
    }

    &__main-header {
        overflow: hidden;
        padding: 0px;
        @include media('>=xl') {
            padding-right: 0!important;
        }
    }

    &__section {
        width: 100%;
        padding: 0px;
    }

    &__logo {
        padding: 0px 5px;
    }
}

.turn {
    margin-top:auto;

    &:hover{
        cursor:pointer;
    }
}

.bi-svg-menu {
    fill: var(--blue-dark);
    color: #FFFFFF
}

.sidebar-nav {
    padding-top: 9px;
    padding-bottom: 22px;
    position: sticky;

    @include media('>=xl') {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #4B4B4B;
        padding: 0px 25px;
        margin: 0px 0px 16px;
    }

    &__item {
        padding: 0 2px 7px 13px;
        position: relative;

        .badge {
            position: absolute;
            left: 33px;
            top: 50%;
            width: fit-content;
            margin-top: -15px;
            font-size: 9px;
            font-weight: 700;
            padding: 2px 4px;
            background: #d93b31;
            color: #fff;
            border: none;
            border-radius: 3px;
            &.hide {
                display: none;
            }
        }

        &:last-child {
            margin-bottom: 35px;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow: hidden;
        padding: 14px 16px;
        font-style: normal;
        font-weight: var(--sidebar-font-weight);
        font-size: var(--sidebar-font-size);
        line-height: 0.9;
        color: #4B4B4B;
        max-height: $nav_btn_height;
        @include media('>=xl') {
            border-bottom: 0px;
            padding: 14px;
            margin-bottom: 1px;
        }
        &:hover, &:active, &:not(.pointer).active {
            color: #1A32BA;
            text-decoration: none;
            border-radius: 4px;
        }

        &:focus, &:focus-within, &:focus-visible, &:active {
            border: none;
            outline: none;
            background: none !important;
        }

        &:hover {
            background-color: #f5f5f5;
        }

        &-text {
            flex: 1 1 auto;
            min-width: 165px;
            line-height: 1.2;
            padding-right: 5px;
        }
        &-icon {
            margin-right: 20px;
            margin-left: 3px;
        }

        &.pointer {
            display: flex;
            align-items: center;

            .sidebar-nav__link-icon {
                margin-right: 20px;
            }

            .sidebar-nav__link-text {
                flex: 1;
            }

            .icon-tabler-chevron-right {
                color: #979797;
                margin-left: -25px;
                transition: transform 0.3s ease;
            }

            &:not(.collapsed) .icon-tabler-chevron-right {
                transform: rotate(90deg);
            }
        }
    }

    &__sublink {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow: hidden;
        padding: 14px 0 14px 25px;
        font-style: normal;
        margin-left: 37px;
        font-weight: var(--sidebar-font-weight);
        font-size: var(--sidebar-font-size);
        line-height: 0.9;
        color: #4B4B4B;
        max-height: $nav_btn_height;
        @include media('>=xl') {
            border-bottom: 0px;
            padding: 14px 0 14px 25px;
            margin-bottom: 1px;
            margin-left: 37px;
        }
        &:hover, &:active, &.active {
            color: #1A32BA;
            text-decoration: none;
            border-radius: 4px;
        }

        &:focus, &:focus-within, &:focus-visible, &:active {
            border: none;
            outline: none;
            background: none !important;
        }

        &-text {
            flex: 1 1 auto;
            min-width: 165px;
        }
        &-icon {
            margin-right: 20px;
        }
    }

    &__subitem {
        &:hover {
            border-radius: 12px;
            background-color: #f5f5f5;
        }
        &.active {
            background: var(--nav-list-item-active-color);
            border-radius: 12px;
            color: #000000;
        }
    }

    .active:not(.collapsed) {
        background: var(--nav-list-item-active-color);
        border-radius: 12px;
        color: #000000;
    }

    &__icon-container {
        display: flex;
        align-items: center;
        z-index: 300;

        .sidebar-nav__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -30px;

            .sidebar-nav__icon-chevron {
                width: 16px;
                height: 16px;
            }
        }
    }
    .dropdown-text{
        min-width: 100px;
    }
    .info-button-item.above-footer {
        width: 100%;
        position: absolute;
        z-index: 99;
    }
}

.nav-back-btn {
    background: #d7e7ff;
    border-radius: 4px;
    height: $nav_btn_height;
    width: 104px;
    border: 0;
    color: #4B4B4B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;
}

.nested-nav {
    padding: 20px;
    .acc-menu li {
        padding: 15px 0;
        &:not(:last-child) {
            border-bottom: solid 1px #ECECEC;;
        }
    }
}

.info-popover {
    border-radius: 5px;
    border: 1px solid #E5E5E5;
    width: 239px;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

    ul {
      padding-left: 0;
      font-size: 14px;
      line-height: 25px;
      list-style: none;
      margin: 0 0;
    }
}

.info-link, .info-link:hover {
    text-decoration: none;
    color: #4d4d4d;
    padding: 10px 15px;
    display: block;
}

.info-link:hover {
    background-color: #E5E5E5;
}

.info-button {
    margin-bottom: 12px;
    width: 100%;
    color: white;
    justify-content: center;

    &:hover {
        background-color: #4f8edc;
        border-color: #4f8edc;
        color: white;
    }
}

.body__sidebar:not(.minimized) .info-button-item .sidebar-nav__link-text {
    flex: inherit;
    min-width: inherit;
}

.body__sidebar .info-button-item .sidebar-nav__link-text {
    display: none;
}

.pointer {
    cursor: pointer;
}

.displayNone {
    display: none !important;
}

.body__sidebar .info-button-item {
    padding: 0;
}

.body__sidebar .info-button {
    border-radius: 0;
}

.body__sidebar .info-button-item .sidebar-nav__link-icon {
    margin: 0;
}

.info-button-item {
    .info-popover {
        z-index: 999999;
    }

    .info-popover .arrow {
        display: none;
    }
}

#main-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}

#main-overlay.show {
    display: block;
}

.root-sidebar:has(.sidebar-nav__subitem.active) [data-toggle="collapse"] {
    background: none;
}

.root-sidebar:has(.sidebar-nav__subitem.active):has(#orderRequestCollapse.displayNone) #sidebar_item_order_request {
    background: var(--nav-list-item-active-color);
}

.root-sidebar:has(.sidebar-nav__subitem.active):has(#deliveriesCollapse.displayNone) #sidebar_item_deliveries {
    background: var(--nav-list-item-active-color);
}
