
#productImages {
    position: relative;

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 400px;
        img { max-width: 100%; }
    }

}

.drift-bounding-box {
    background: #eee;
    border-radius: 10px;
    border: 1px solid white;
    opacity: .4;
    z-index: 9999;
}

.zoom-wrapper {
    position: relative;

    .zoom {
        position: absolute;
        left: 100%;
        top: 5px;
        width: 400px;
        height: 400px;
        z-index: 99999;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-shadow: 11px 10px 30px -10px rgba(0,0,0,0.75);
        background-color: white;
        &:empty {
            display: none;
        }
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
          object-position: center;
        }
    }

}

#productImagesThumbs {
    margin-top: 20px;
    width: 100%;

    .swiper-slide {
        width: auto;
        opacity: 0.4;
        border: 1px solid #4D83D6;
        cursor: pointer;
        padding: 2px;
        border-radius: 4px;
        img { max-width: 100%; }
    }

    .swiper-slide-thumb-active {
        opacity: 1;
      }
}
