@import './ugmk_design_content.scss';

.panel-body {
  padding: 24px 24px 50px 24px !important;
  background-color: #FFFFFF;

  &.panel-pb-small {
    padding-bottom: 10px !important;
  }

  > form.form-directories-mapping {
    overflow-x: auto;
  }
}

.contractor-limits {
  &__heading {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    padding: 0;
    margin: 24px 10px 24px 0;
    color: #191919;

    &--form-heading {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  &__form {
    max-width: 400px;
    margin-bottom: 10px;

    input[type="radio"],
    input[type="checkbox"] {
      width: 15px;
      height: 15px;
      accent-color: #2022ED;
      cursor: pointer;
    }

    label {
      @extend .u-text-size-12;
      @extend .u-text-color-gray;
      margin-bottom: 6px !important;
      line-height: 18px;
    }

    #div_id_period label {
      margin-bottom: 0px !important;
    }

    label[for*="id_period"] {
      color: #191919 !important;
    }

    label[for*="id_period"].control-label {
      @extend .u-text-color-gray;
    }

    .select2-selection__rendered {
      color: #191919 !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    select.form-control,
    input[type="number"] {
      height: 40px;
    }

    .radio {
      margin-top: 6px;
      margin-bottom: 0px;
    }

    button[type="submit"] {
      color: #2022ED !important;
      background-color: #E0E7FF;

      &:hover {
        background-color: #EAEEFC;
      }
    }
  }

  &__form-check-inline {
    display: inline-block;
    margin-right: 1rem;
  }

  &__create {
    color: #2022ED !important;
    height: 40px;
  }

  &__update {
    color: #2022ED !important;
    height: 40px;
    white-space: nowrap !important;
  }

  &__back {
    display: flex;
    width: fit-content;
    padding: 9px 16px 9px 16px !important;

    .bi {
      margin-left: 0 !important;
    }
  }
}

.bi-history-dot {
  width: 20px;
  height: 20px;
}

.history-limits {
  list-style: none;
  float: left;
  width: 100%;
  margin-top: 24px;

  &-title {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &-info {
    display: flex;
    flex-direction: column;
    padding-left: 11px;
    gap: 3px;

    .history-changes {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }

    .history-diff {
      display: flex;
      align-items: center;
      gap: 8px;

      .diff {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  li {
    padding-bottom: 20px;
    float: left;
    width: 100%;
    position: relative;
    display: flex;

    &:before {
      content: '';
      position: absolute;
      top: 20px;
      width: 2px;
      height: 100%;
      left: 9px;
      background: #EAEEFC;
    }
  }

  li:last-child:before {
    display: none;
  }

  .bi-history-arrow {
    width: 20px;
    height: 20px;
    fill: gray;
  }
}

.history-limits-wrapper {
  margin-top: 20px;
}
