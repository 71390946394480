.ugmk-footer {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 24px 30px;
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  max-height: unset;
  height: auto;
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  width: calc(100% - 16px);
  margin-top: 15px;
  margin-right: 8px;
  margin-left: 8px;
  background: #ffffff;
}

.ugmk-footer .ugmk-footer__logo {
  grid-area: 1;
  margin-bottom: 21px;

  & > a > img {
    width: 131px;
    height: 43px;
    object-fit: contain;
  }
}

.ugmk-footer .ugmk-footer__social {
  grid-area: 1 / 4 / 1 / 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
  gap: 24px;
}

.ugmk-footer .ugmk-footer__links-grid {
  grid-area: 2/1/2/5;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-top: 1px solid #E5E5E5;
  padding-top: 44px;
}

.ugmk-footer .ugmk-footer__link-item {
  display: flex;
  flex-direction: column;

  a {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #4B4B4B;
    margin-bottom: 8px;
  }

  &--title {
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #000;
  }
}

.ugmk-footer .ugmk-footer__copyright {
  margin-top: 0;
  text-align: center;
  color: #7D7D7D;
  grid-area: 3 / 1 / 3 / 5;
}


.footer {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  max-height: unset;
  height: auto;
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;


  &__main-content-block {
    display: flex;
    align-items: center;
    padding: 24px 15px;
    color:var(--black-2000);
    gap: 24px;
    margin: 0 auto;
    width: 100%;
    max-height:200px;
    max-width:1530px;

    a {
      color:var(--black-2000);
    }

  }

  &__media-wrapper-links:hover {
    color: var(--blue-dark);
    background-color: rgba(224, 231, 255, 1);
    cursor: pointer;
     a {
       color: var(--blue-dark);
     }

  }

  a:hover {
    color: var(--blue-dark);
  }

  &__info-block {
    width: 21%;
    display: flex;
    flex-direction: column;
    gap: 21px;
    height: 100%;
    margin-right: 15px;


    &-logo {
        width: 160px;
        height: auto;
    }
  }

  &__info-text {
    display: block;
    margin-right:80px;
  }

  &__links-content {
    height: 100%;
    align-self: flex-start;
    width:40%;
    display: flex;

    ul{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap:10px;
    }

  }

  &__support {
    margin-left: auto;
    text-align: right;
    width: 23%;

    &-title {
      display: block;
      font-weight: 700;
      color: var(--black-1000);
      text-transform: uppercase;
      margin-bottom: 6px;
    }
    &-contacts {
      display: block;
      font-weight: 700;
      margin-bottom: 6px;
    }
  }

  &__media-wrapper {
    display: flex;
    gap: 10px;

    &-links {
      color: #333;
      background-color: rgba(255, 255, 255, 0);
      border-radius: 27px;
      padding: 5px 10px;
      border: 1px solid #4d83d6;
      -webkit-box-shadow: none;
      box-shadow: none;
      gap: 12px;

      &-img {
        margin-right: 6.3px;
      }
    }
  }

  &__legal-content {
    display: flex;
    align-items: center;
    background-color: rgba(224, 231, 255, 1);
    color: rgba(26, 50, 186, 1);
    gap: 24px;
  }

  &__legal-content-wrapper {

    margin: 0 auto;
    max-width: 1530px;
    width: 100%;
    padding: 24px 15px;
    display: flex;
  }

  &__copyright {
    margin-right: auto;
  }

  &__legal-links-wrapper {
    display: flex;
    &-list{
      display: flex;
      gap: 10px;
      margin: 0 auto;
    }
   a {
      color: rgba(26, 50, 186, 1);
    }
    a:hover {
      text-decoration: underline;
    }
  }

  &__text-contrast{
    font-weight: 700;
  }
}

