.breadcrumb {
    background: #fff;
    padding: 20px 20px;
    margin: 0px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;

    &.bordered {
        border-bottom: 1px solid #E5E5E5;
    }

    li {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        white-space: nowrap;

        a, span {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 15px;
        }

        a {
            color: #4B4B4B;
            text-decoration: none;
            border-bottom: 1px solid #E5E5E5;

            &:hover {
                color: lighten(#4B4B4B, 15%);
                border-bottom-color: transparent;
            }
        }

        span {
            color: #a5a5a5;
        }

        + li:before {
            content: '';
            background-image: url('icons/breadcrumb-arrow.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 18px;
            height: 18px;
            display: inline-block;
            margin: 0px 5px -5px 8px;
            padding: 0px;
        }
    }
}
