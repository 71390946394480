.has-dropdown {
    position: relative;
    &__content {
        position: absolute;
        left: 0px;
        top: 100%;
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s ease-in-out;
        z-index: -1;
        min-width: 100%;
        &.dropdown-visible {
            pointer-events: auto;
            visibility: visible;
            opacity: 1;
            z-index: 1000;
        }
        &.dropdown-relative {
            position: relative;
            left: auto;
            top: auto;
        }
    }
    &__link {
        cursor: pointer;
    }
}

[data-dropdown-toggle] {
    .dropdown-is-visible {
        display: none;
    }
    &.dropdown-visible {
        .dropdown-is-hidden {
            display: none;
        }
        .dropdown-is-visible {
            display: block;
        }
    }
}

.dropdown {
    &__link {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        color: #4B4B4B;
    }

    &__body {
        width: 210px;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        padding: 10px;
        margin: 8px 0px 0px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        // &:before {
        //     content: '';
        //     position: absolute;
        //     left: 10px;
        //     bottom: 100%;
        //     border-left: 8px solid transparent;
        //     border-right: 8px solid transparent;
        //     border-bottom: 6px solid #E5E5E5;
        // }

        &_auto {
            width: auto;
        }

        &.right {
            right: 0px;
            &:before {
                right: 10px;
            }
            &-xl {
                @include media(">=xl") {
                    right: 0px;
                }
                &:before {
                    @include media(">=xl") {
                        right: 10px;
                    }
                }
            }
        }

        &-link {
            display: block;
            padding: 10px 20px;
            &:hover, &:active {
                text-decoration: none;
                background: #E5E5E5;
                color: #4B4B4B;
            }
            &.active {
                color: #fff;
                background: #4d83d6;
            }
        }
    }
}

.profile-dropdown {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #4B4B4B;
    padding: 0 20px;
    border-radius: 16px;
    border: none;
    box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
    &__section {
        padding: 20px 0;
        border-bottom: 1px solid #E5E5E5;
        &_header {
            padding-top: 23px;
            padding-bottom: 13px;
        }
        &_contract {
            padding-top: 16px;
        }
        &_logout {
            padding-top: 15px;
            padding-bottom: 14px;
            border-bottom: 0px;
        }
    }
    &__title {
        display: block;
        font-weight: 700;
        margin-bottom: 9px;
        overflow-wrap: anywhere;
        &_manager {
            margin-top: 20px;
        }
    }

    &__sub-title {
      margin-bottom: 9px;
        color: var(--black-2000)
    }

    &__contract-link {
        color: #4D83D6;
        display: flex;
    }

    &__logout-link {
        display: flex;
        font-size: 12px;
    }

    &__settings-link {
        display: flex;
        font-size: 12px;
        margin-top: 12px;
        color: rgba(26, 50, 186, 1);
        &:hover, &:active {
            color: #292929;
            text-decoration: none;
        }
        &:first-child {
            margin-top: 0px;
        }
    }

    &__green {
        color: #379653;
    }
}

[data-dropdown=exchange-rates] > .dropdown__body-text {
    padding: 10px 20px;
    &:hover {
      cursor: default;
    }
}

.company-dropdown {
    padding: 10px 0px;
}

.contract-dropdown {
    padding: 10px 0px;
    width: 280px;
}


.dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #4d4d4d;
    white-space: nowrap
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    text-decoration: none;
    color: #000;
    background-color: #d2d3d6
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #d2d3d6
}

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
    color: #aeafb1
}

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    cursor: not-allowed
}

@media (max-width: 576px) {

    .dropdown-content-text {
        display: none;
    }

}

@media screen and (max-width: 375px) {
	.dropdown__body {
		width: 180px !important;
	}
	.dropdown__link {
		font-size: 11px !important;
	}
    .top-header__dropdown-body.right {
        right: 10px !important;
    }
}
