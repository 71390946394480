
.u {
  &-text {

    &-color {
      &-dark {
        color: #000000 !important;
      }

      &-muted {
        color: #969696 !important;
      }

      &-success {
        color: #51923B !important;
      }

      &-danger {
        color: #EB5757 !important;
      }

      &-blue {
        color: #2022ED !important;
      }

      &-label {
        color: #818BAD !important
      }

      &-gray {
        color: #646464 !important;
      }
    }

    &-size {
      &-11 {
        font-size: 11px !important;
      }

      &-12 {
        font-size: 12px !important;
      }

      &-13 {
        font-size: 13px !important;
      }

      &-18 {
        font-size: 18px !important;
      }

      &-22 {
        font-size: 22px !important;
      }
    }

    &-transform {
      &-upper {
        text-transform: uppercase !important;
      }
    }

    &-weight {
      &-bold {
        font-weight: 700 !important;
      }

      &-normal {
        font-weight: 400 !important;
      }
    }
  }
}
