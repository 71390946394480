$alert-success: #f5feef;
$alert-info: rgb(74, 141, 242);
$alert-warning: rgb(242, 153, 74);
$alert-danger: rgb(242, 74, 74);
$alert-success-clear: rgb(168, 242, 74);


.alert {
    padding: 16px 15px 16px 51px;
    margin-bottom: 20px;
    border: 1px solid #9A9A9A;
    background-color: #efefef;
    background-image: url('icons/alert.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: left 15px center;
    border-radius: 8px;
    width: fit-content;
    h4 {
        margin-top: 0;
        color: inherit
    }

    p, ul {
        margin-top: 0px;
        margin-bottom: 0;
    }

    p + p,
    p + ul,
    ul + p,
    ul + ul  {
        margin-top: 5px;
    }
    .close {
        opacity: 1;
    }

    > div > span {
        font-size: var(--alert-description--font-size);
        font-weight: var(--alert-description-font-weight);
    }
}

.alert-dismissable {
    padding-right: 35px;
    .close {
        position: relative;
        top: -2px;
        right: -21px;
        color: inherit;
    }
}

.alert-success {
    background-color: rgba($alert-success, .1);
    border-color: #B7E0AD;
    background-image: url('icons/alert-success.svg');
    background-position-y: 40%;
    color: #60872F;

  &--orders {
    background-color: #EFF8EC !important;
    border: none;
    background-image: url('icons/check-alert-order.svg') !important;
    color: #191919 !important;
    font-size: var(--alert-title-font-size);
    font-weight: var(--alert-title-font-weight);
}

    &[data-message="with-title"] {
        background-position-y: 30%;
    }
    &.floating-alert {
        background-color: $alert-success;
        color: #646464;
        width: fit-content;
    }

    hr {
        border-top-color: #60872F;
    }

    a {
        color: darken(#60872F, 15%);
    }
}

.alert-info {
    background-color: rgba($alert-info, .1);
    border: 1px solid #C4C8F3;
    background-image: url('icons/alert-info.svg');
    background-repeat: no-repeat;
    color: #4E60C0;

    &.floating-alert {
        background-color: $alert-info;
        color: #ffffff;
    }

    hr {
        border-top-color: #4E60C0;
    }

    a {
        color: darken(#4E60C0, 15%);
    }

    strong.wallet-number {
        word-break: break-word;
    }
}

.alert-warning {
    background-color: rgba($alert-warning, .1);
    border-color: #FFCDA0;
    background-image: url('icons/alert-warning.svg');
    background-repeat: no-repeat;
    color: #B47742;

    &.floating-alert {
        background-color: $alert-warning;
        color: #ffffff;
    }

    hr {
        border-top-color: #B47742;
    }

    a {
        color: darken(#B47742, 15%);
    }
}

.alert-warning-without-icon {
    background-color: rgba($alert-warning, .1);
    border-color: #FFCDA0;
    color: #B47742;

    &.floating-alert {
        background-color: $alert-warning;
        color: #ffffff;
    }

    hr {
        border-top-color: #B47742;
    }

    a {
        color: darken(#B47742, 15%);
    }
}

.alert-danger {
    background-color: transparent;
    border: 2px solid #FC9393;
    background-image: url('icons/alert-danger-2.svg');
    &.floating-alert {
        background-color: $alert-danger;
        color: #ffffff;
    }
    hr {
        border-top-color: #B44242
    }

    a {
        color: darken(#B44242, 15%);
    }

    [data-id="resend-to-us"] {
      color: #000000;
      margin-left: 20px;
    }

    .alert-danger__bold-text {
        font-size: var(--alert-title-font-size);
        font-weight: var(--alert-title-font-weight);
    }
}

.alert-danger-flex {
    display: flex;
    justify-content: space-between;

    > a {
        display: block;
        margin-left: 8px;
    }
}


.alert-sign-before {
    position:relative;

    .popover {
        min-width: 220px;
    }

    &.show::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin-top: 5px;
        background-image: url('icons/alert-danger.svg');
        background-repeat: no-repeat;
        color: red;
        left: -30px;
    }
}

.alert-clear {
    margin-bottom: 20px;
    border: 1px solid #9A9A9A;
    background-color: #efefef;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: left 15px center;
    border-radius: 8px;

    h4 {
        margin-top: 0;
        color: inherit
    }

    p, ul {
        margin-top: 0px;
        margin-bottom: 0;
    }

    p + p,
    p + ul,
    ul + p,
    ul + ul  {
        margin-top: 5px;
    }
    .close {
        opacity: 1;
    }
}

.alert-success-clear {
    background-color: rgba($alert-success-clear, .1);
    border-color: #B7E0AD;
    color: #60872F;

    &.floating-alert {
        background-color: $alert-success-clear;
        color: #ffffff;
    }

    hr {
        border-top-color: #60872F;
    }

    a {
        color: darken(#60872F, 15%);
    }
}

.floating-alert {
  position: fixed;
  top: 220px;
  z-index: 1000;
  left: 50%;

  transform: translate(-50%);
  border: none !important;
  border-radius: 8px;
  width: fit-content;
  margin: 0;
  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    opacity: .5;
  }
}

.static-alert {
  width: fit-content !important;
}
