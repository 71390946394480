.products-panel {
    .products-panel-heading {
        h4,
        a {
          color: #4d4d4d!important;
        }
    }
}

.product--item-table {
  &-line {
    position: relative;

    &-badge {
      margin: 5px 0;
    }

    .product-discount {
      position: unset;
    }

    &-col.col2 {
      flex: 1 1 auto;
      margin: 0 auto;
      display: flex;
      flex-direction: row;

      @include media("<=sm") {
        align-items: center;
        .product-discount {
          margin-left: 10px;
        }
      }
    }
  }

  .product-left {
    width: 30px !important;
    position: relative;
  }
}

.product-discount {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 5px;
  min-width: 33px;
  max-height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: #EB4857;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
}

.cartproduct {
  .product-discount {
    position: inherit;
    right: 10px;
    padding: 10px 5px;
    min-width: 120px;
    max-height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #EB4857;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 13px;
    color: #ffffff;
    .label-success {
      background-color: #EB4857;
    }
  }
}

.product--item-list .product-discount{
  top: 7px;
}

.product--item-table .product-discount{
  margin-left: 10px;
}

.product--item-grid .product-discount {
  margin: 5px;
  margin-top: 10px;
}

.product-price-right {
  display: flex;
  justify-content:space-between;
}

.product-discount-detail {
  position: absolute;
  display: flex;
  padding: 10px 5px;
  top: 0;
  right: 10px;
  min-width: 33px;
  max-height: 33px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: #EB4857;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: #ffffff;
}

.product .product-item__code, .product-code {
  color: #B9B9B9;
  background: #F1F1F1;
  padding: 2px 10px;
  border-radius: 10px;
  display: inline-block;
}

.product-code {
  margin-right: 5px;
}

.product--item-grid .product-title small {
  white-space: nowrap;
}

.product--stickers {
  margin-bottom: 10px;
  margin-top: 10px;
}

.product--item-grid .product--stickers {
  text-align: center;
}

.product--item-list--code, .product--item-grid--code {
  clear: both;
  font-size: 14px;
  margin: 5px 0;
}

#product-preview {
  overflow-y: auto;
}
