.form-control {
    border: 1px solid #C9CDDC;
    border-radius: 4px;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #4B4B4B;
    margin: 0px;
    min-height: 39px;
    box-shadow: none;
    width: 100%;
    &:placeholder {
        color: #979797;
    }
    &:focus {
        color: #4B4B4B;
        box-shadow: none;
        outline: none;
        border: 1px solid #C9CDDC;
    }
    &:disabled, &[readonly] {
        background-color: #fff;
        border-color: #f4f4f4;
        color: #BBBBBB;
        &::placeholder {
            color: #BBBBBB;
        }
      &.daterange {
        background-color: #fff;
        cursor: default;
        border: 1px solid #C9CDDC;
        color: #4b4b4b;
      }
    }
    &:valid, &.is_valid {
        border-color: #C9CDDC;
    }

    &.input {
        &-sm {
            height: auto;
            padding: 9px 14px;
            font-size: 14px;
            line-height: 21px;
        }
        &-lg {
            height: auto;
            padding: 13px 14px;
            font-size: 14px;
            line-height: 21px;
        }
    }
}

.form-control-select {
    height: 36px;
    width: 100%;
}

.search-min {
    min-width: 350px;

    @include media('<=sm') {
        min-width: unset;
    }
}

select.form-control {
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4583 7.91667L9.49992 11.875L5.54159 7.91667L13.4583 7.91667Z' fill='%23979797'/%3E%3C/svg%3E%0A");
    background-position: center right 7px;
    background-size: 19px 19px;
    background-repeat: no-repeat;
    padding-right: 25px;
    appearance: none;
    &.input {
        &-sm {
            padding-right: 25px;
        }
        &-lg {
            padding-right: 25px;
        }
    }
}

select[data-class]  {
  background-image: none;
}

.form-checkbox {
    position: relative;
    display: inline-flex;

    .form-checkbox__input {
        visibility: hidden;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0px;
        top: 0px;

        &:checked + .form-checkbox__label {
            &:before {
                border: 0px;
                background-image: url('icons/checkbox-check.svg');
            }
        }
    }

    .form-checkbox__label {
        position: relative;
        margin: 0px;
        padding: 0px 0px 0px 22px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #4B4B4B;

        &:before {
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            width: 15px;
            height: 15px;
            border: 1px solid #979797;
            border-radius: 3px;
            background-color: #fff;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
}

.form-radio {
    position: relative;
    display: inline-flex;

    .form-radio__input {
        visibility: hidden;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0px;
        top: 0px;

        &:checked + .form-radio__label {
            &:before {
                border-color: #4D83D6;
            }
            &:after {
                content: '';
                position: absolute;
                left: 3px;
                top: 3px;
                width: 9px;
                height: 9px;
                background: #4D83D6;
                border-radius: 50%;
            }
        }
    }

    .form-radio__label {
        position: relative;
        margin: 0px;
        padding: 0px 0px 0px 22px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #4B4B4B;

        &:before {
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            width: 15px;
            height: 15px;
            border: 1px solid #979797;
            border-radius: 50%;
            background-color: #fff;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
}

.btn {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5em;
    color: #292929;
    border: 0px;
    background-color: #E5E5E5;
    border-radius: 4px;
    padding: 7px 20px;
    box-shadow: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: normal;
    &:hover, &:active, &:focus {
        border: 0px;
        box-shadow: none;
        background-color: #ccc;
        color: #292929;
    }
    &-lg {
        padding: 14px 20px;
    }
    &-sm {
        padding: 8px 16px;
    }
    &-primary {
        background-color: #4D83D6;
        color: #fff;
        &:hover, &:active, &:focus {
            background-color: darken(#4D83D6, 10%);
            color: #fff;
        }
    }
    &-success {
        background-color: #27AE60;
        color: #fff;
        &:hover, &:active, &:focus {
            background-color: darken(#27AE60, 10%);
            color: #fff;
        }
    }
    &-light {
        background-color: #F5F5F5;
        &:hover, &:active, &:focus {
            background-color: darken(#F5F5F5, 10%);;
        }
    }
    &-danger {
        color: #EB5757;
        &:hover, &:active, &:focus {
            color: darken(#EB5757, 10%);;
        }
    }

    svg.bi {
        fill: currentColor;
        width: 18px;
        min-width: 18px;
        height: 18px;
    }

    .badge {
        padding: 9px 7px;
        border-radius: 15px;
        min-width: 28px;
    }

    .fa:not(.no-text):first-child,
    .bi:first-child,
    .badge:first-child {
        margin-right: 10px;
    }

    .fa:not(.no-text):last-child,
    .bi:last-child,
    .badge:last-child {
        margin-left: 10px;
    }
}

div {
    .select2-container.select2-container--focus {
        .select2-selection {
            border: 1px solid #C9CDDC;
        }
    }
    .select2-container, .select2-container--default, .select2-container--bootstrap4 {
        &.form-control {
            border: 0px;
        }

        &.input {
            &-lg {
                height: auto;
                min-height: 49px;
                .select2-choice {
                    height: 49px;
                    line-height: 49px;

                    .select2-search-choice-close {
                        top: 17px;
                    }
                }
            }
            &-sm {
                height: auto;
                min-height: 36px;
                .select2-choice {
                    height: 36px;
                    line-height: 36px;

                    .select2-search-choice-close {
                        top: 11px;
                    }
                }
            }
        }

        .select2-choice {
            height: 41px;
            padding-left: 14px;
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 41px;
            color: #4B4B4B;
            border: 1px solid #C9CDDC;
            border-radius: 4px;
            background: #fff;
            box-shadow: none;

            .select2-arrow {
                background: transparent;
                border: 0px;
                width: 26px;
                b {
                    background-color: #fff;
                    background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4583 7.91667L9.49992 11.875L5.54159 7.91667L13.4583 7.91667Z' fill='%23979797'/%3E%3C/svg%3E%0A") !important;
                    background-size: 19px 19px !important;
                    background-position: center left !important;
                    background-repeat: no-repeat !important;
                }
            }

            .select2-search-choice-close {
                top: 13px;
            }
        }

        .select2-selection {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 41px;
            color: #4B4B4B;
            border: 1px solid #C9CDDC;
            border-radius: 4px;
            background: #fff;
            box-shadow: none;

            &--single {
                height: 40px !important;

                .select2-selection__rendered {
                    padding: 0px 20px 0px 14px;
                    min-height: 39px;
                    line-height: 39px;
                }

                .select2-selection__arrow {
                    background-color: #fff;
                    background: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4583 7.91667L9.49992 11.875L5.54159 7.91667L13.4583 7.91667Z' fill='%23979797'/%3E%3C/svg%3E%0A");
                    background-size: 19px 19px;
                    background-position: center left;
                    background-repeat: no-repeat;
                    width: 26px;
                    height: auto;
                    top: 0px;
                    bottom: 0px;

                    b {
                        border: 0px;
                    }
                }
            }

            &--multiple {
                min-height: 41px;
                line-height: 0px;
                border-radius: 8px;

                .select2-selection__rendered {
                    padding-left: 14px;
                    padding-right: 30px;
                    padding-bottom: 5px;
                    line-height: 1em;
                    background-image: url("data:image/svg+xml,%3Csvg width=%2716%27 height=%2716%27 viewBox=%270 0 16 16%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M4 6L8 10L12 6%27 stroke=%27%23C8C8C8%27 stroke-width=%271.5%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3C/svg%3E%0A");
                    background-position: center right 10px;
                    background-size: 16px 16px;
                    background-repeat: no-repeat;
                    appearance: none;
                }

                .select2-selection__choice {
                    padding: 5px;
                    line-height: 1em;
                }

                .select2-search__field {
                    min-height: 29px;
                    line-height: 29px;
                    max-width: 100%;
                    background-color: #fff;
                    padding-left: 0px;
                }

                .select2-search {
                    &--inline {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

tr.kontirovki-block div .select2-container .select2-selection--single, div .select2-container--default .select2-selection--single {
  height: 33.5px !important;
}

article.product-cell div .select2-container .select2-selection--single, div .select2-container--default .select2-selection--single {
  height: 41px !important;
}

.select2-dropdown {
    border: 1px solid #C9CDDC !important;
}

.select2-drop {
    &.select2-drop-active {
        border-color: #C9CDDC;
    }
}

.form-group {
    &.form-group_w-auto {
        width: auto;
        min-width: auto;
    }
}

.emojionearea.emojionearea-inline {
    height: 34px !important;
}

div .select2-container--bootstrap4 .select2-selection {
  line-height: 38px;
  height: 38px!important;
  &--single .select2-selection__rendered {
      min-height: 37px;
      line-height: 37px;
  }
}

select.cronfield-form-control {
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4583 7.91667L9.49992 11.875L5.54159 7.91667L13.4583 7.91667Z' fill='%23979797'/%3E%3C/svg%3E%0A");
    background-position: center right 7px;
    background-size: 19px 19px;
    background-repeat: no-repeat;
    padding-right: 25px;
    appearance: none;
    &.input {
        &-sm {
            padding-right: 25px;
        }
        &-lg {
            padding-right: 25px;
        }
    }
}

.cronfield-form-control {
    border: 1px solid #C9CDDC;
    padding: 7px 15px;
    border-radius: 4px;
    background-color: #fff;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #4B4B4B;
    margin: 0px;
    min-height: 39px;
    box-shadow: none;
}

[max-content] {
    min-width: max-content;
}

.vue-slider-process {
    background-color: #2022ED !important;
}

.switch-button {
    --color: #2022ED !important;
}
