.language_dropdown {
    &__icon {
        width: 14px;
        height: 15px;
        margin-right: 4px;
        @include media("<sm") {
          width: 20px;
          height: 21px;
        }

    }
    &__btn {
        padding: 10px 20px;
        margin: 0px;
        width: 100%;
        border-radius: 0px;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #4B4B4B;
        background-color: transparent;
        justify-content: flex-start;
        text-align: left;
        &:hover, &:active, &.active {
            text-decoration: none;
            background-color: #E5E5E5;
        }

        &-text {
            flex: 1 1 auto;
        }
    }
    &__row {
        margin-top: 1px;
        &:first-child {
            margin-top: 0px;
        }
    }
}

.language-dropdown {
    padding: 5px 0px;
}
