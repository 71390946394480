$sidemenu_width_maximized: 265px;
$sidemenu_width_minimized: 80px;
$sidemenu_width_minimized_min: 80px;

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

body {
  display: block;
  background-color: #F7F7F7;
}
%root-sidebar_opened {
  @include media(">=1480px") {
    flex: 0 0 $sidemenu_width_minimized;
    max-width: $sidemenu_width_minimized;
    transition: max-width 0.3s ease-in-out, flex-basis 0.3s ease-in-out;

    &.open-sidebar ~ #root-content {
      width: 89% !important;
      margin-left: 180px !important;
      max-width: calc(100% - $sidemenu_width_maximized + #{$sidemenu_width_minimized});
    }

    .info-button-item .sidebar-nav__link-text {
      display: block;
    }

    &:hover:not(.open-sidebar) {
      max-width: $sidemenu_width_maximized;
      flex-basis: $sidemenu_width_maximized;
    }


    &:hover:not(.open-sidebar) ~ #root-content {
      margin-left: 180px !important;
      width: 89% !important;
      max-width: calc(100% - $sidemenu_width_maximized + #{$sidemenu_width_minimized});
      }
    ~ #root-content {
      width: 100% !important;
      transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
    }

    .info-button-item {
      padding: 0px 8px;
    }
    .info-button {
      border-radius: 4px;
    }
    .info-button-item .sidebar-nav__link-icon {
      margin-right: 22px;
    }
  }
  ~ .body__content {
    width: calc(100% - #{$sidemenu_width_maximized});
  }
}


.body {
  padding: 0px;
  margin: 0px;
  width: 100%;
  min-width: 375px;
  min-height: 100%;

  &__wrapper {
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    background: #f7f7f7;
    display: flex;
  }
  &__sidebar {
    @include media("<=1480px") {
      flex: 0 0 $sidemenu_width_minimized;
      max-width: $sidemenu_width_minimized;
      transition: max-width 0.3s ease-in-out, flex-basis 0.3s ease-in-out;

      &.open-sidebar ~ #root-content {
        transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
        width: 87% !important;
        margin-left: 180px !important;
        max-width: calc(100% - $sidemenu_width_maximized + #{$sidemenu_width_minimized});
      }


      & + .body__content > .root-footer {
        margin-left: -$sidemenu_width_maximized;
        width: calc(100% + #{$sidemenu_width_maximized});
      }
      &:hover:not(.open-sidebar) {
        max-width: $sidemenu_width_maximized;
        flex-basis: $sidemenu_width_maximized;
        transition: max-width 0.3s ease-in-out, flex-basis 0.3s ease-in-out;
      }

      &:hover:not(.open-sidebar) ~ #root-content {
        margin-left: 180px !important;
        width: 87% !important;
        transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
        max-width: calc(100% - $sidemenu_width_maximized + #{$sidemenu_width_minimized});

      }


      &:not(:hover):not(.open-sidebar) {
        max-width: $sidemenu_width_minimized;
        flex-basis: $sidemenu_width_minimized;
        transition: max-width 0.3s ease-in-out, flex-basis 0.3s ease-in-out;
      }

      &:not(:hover):not(.open-sidebar) ~ #root-content {
        margin-left: 0;
        width: 100%;
        transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
      }


    }
  }
  &__sidebar {
    @include media("<=1340px") {
      display: none !important;
      max-width: 265px;

      &.open-sidebar ~ #root-content {
        transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
        width: 100% !important;
        margin-left: 0 !important;
        max-width: 100%;
      }

      &:hover:not(.open-sidebar)~#root-content{
        margin-left: 0 !important;
        width: 100% !important;
        max-width: 100%;
      }

      &:hover ~ #root-content {
        margin-left: 0 !important;
        width: 100% !important;
        max-width: 100%;
      }

      .turn {
        display: none;
      }
    }



  }


  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
    width: calc(100% - #{$sidemenu_width_minimized});
    transition: all 0.2s ease-in-out;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .body__wrapper {
    flex: 1 1 auto;
  }

  .body__content {
    min-height: 0;
  }

  #page-content {
    min-height: 0;
  }

  #wrap {
    min-height: 0;
  }
}

.body__sidebar.root-sidebar_opened {
  @extend %root-sidebar_opened;
}

.flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 1440px;
}

.roboto {
  font-family: Roboto, "sans-serif";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #4b4b4b;
}

.link {
  color: #4b4b4b;
  text-decoration: none;
  &:hover,
  &:active {
    color: #2022ED;
    text-decoration: underline;
  }

  &_has-icon {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    &-start {
      align-items: flex-start;
    }
  }

  &_red {
    color: #eb5757;
    &:hover,
    &:active {
      color: #eb5757;
    }
  }
}

.d-none {
  display: none;
  &-xs {
    @include media("<sm") {
      display: none !important;
    }
  }
  &-above-xs {
    @include media(">=sm") {
      display: none !important;
    }
  }
  &-sm {
    @include media(">=sm", "<lg") {
      display: none !important;
    }
  }
  &-lg {
    @include media(">=lg", "<xl") {
      display: none !important;
    }
  }
  &-xl {
    @include media(">=xl") {
      display: none !important;
    }
  }
  &-below-sm {
    @include media("<sm") {
      display: none !important;
    }
  }
  &-below-lg {
    @include media("<lg") {
      display: none !important;
    }
  }
  &-below-xl {
    @include media("<xl") {
      display: none !important;
    }
  }
}

.mw-1440 {
  margin: 0px auto;
  max-width: 1440px;
}
.mw-900 {
  margin: 0px auto;
  max-width: 900px;
}

.scroll-lock {
  overflow: hidden !important;
}

.opened {
  &__visible {
    display: none;
  }
  .opened__visible {
    display: block;
  }
  .opened__hidden {
    display: none;
  }
}

.root-content-main {
  flex: 1 1 auto;
  width: 100%;
  padding-top: 12px;
  background-color: #f5f5f5;
}

.rub::before {
  content: "руб. ";
}

.eur::before {
  content: "\20AC";
}

.usd::before {
  content: "$";
}

.page-leftbar-inner {
  &_chat {
    height: 100vh;
    min-height: 600px;
    .contacts-search--js {
      max-width: 215px !important;
    }
  }
  .category-offset-1 {
    margin-left: 10px;
  }
  .category-offset-2 {
    margin-left: 20px;
  }
  .category-offset-3 {
    margin-left: 30px;
  }
  .category-offset-4 {
    margin-left: 40px;
  }
  .category-offset-5 {
    margin-left: 50px;
  }
}

.ui-autocomplete {
  width: auto !important;
  min-width: 230px;
  z-index: 1000 !important;
}

.hidden {
  display: none !important;
  height: 0;
  margin: 0;
  padding: 0;
}

.overflow-hidden {
  overflow: hidden;
}

#image_search_button {
  padding: 11px 20px;
  > i.fa {
    margin-right: 0 !important;
  }
}

#body-content .input-group__favourites .form-group #image_search_button {
  height: 39px;
}

.alert_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

a {
  color: #4f8edc;
  text-decoration: none;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #aeafb1;
}

.warehouse--js {
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#extended-search {
  position: absolute;
  color: #777;
  font-size: 18px;
  z-index: 1;
  top: 1px;
  right: 110px;
  padding: 8px 16px;
  border-left: 1px solid #d2d3d6;
  background-color: white;

  :hover,
  :focus {
    text-decoration: none;
    color: #000000;
    border-left: 1px solid #cbced0;
  }
}

@media screen and (max-width: 768px) {
  #extended-search {
    right: 50px;
    width: 42px;
    text-align: center;
  }
}

@media screen and (max-width: 1300px) {
  .options-normal {
    display: none;
  }
  #sort-menu-button {
    cursor: pointer;
    text-align: right;
  }
  .options-small {
    display: grid !important;
    position: absolute;
    right: 30px;
    background-color: #f2f2f2;
    z-index: 10;
    width: 150px;
  }
  .option-item {
    display: none;
  }
}

.sort-option-items {
    text-decoration: none !important;
  }

.propsearch {
  margin-bottom: 0;

  .form-inline .form-group {
    margin-right: 10px;
  }

  .form-inline div.checkbox {
    margin-right: 10px;
  }

  .form-group {
    line-height: 10px;
    margin-bottom: 0 !important;
  }
}

.inline-field {
  h5 {
    width: 35%;
    display: inline-block;
    font-weight: 400;
  }

  .form-control {
    width: 60%;
    display: inline-block;
  }
}

.inline-filter,
.inline-filter .inline-field {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 10px;
}

.inline-filter {
  .inline-field h5 {
    width: auto;
    padding-right: 5px;
  }

  .inline-field .form-control {
    width: auto;
  }

  .inline-field {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
  }
}

.extended-search {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  :before {
    content: none;
  }
}

.inline-field h5,
.extended-search select {
  width: 50%;
}

.autocomplete-container {
  position: relative;
}

.autocomplete-container-search .form-group {
  margin-bottom: 0px;
}

.autocomplete-dropdown {
  display: none;
  position: absolute;
  top: 50px;
  border: 1px solid #e5e5e5;
  left: -1 px;
  max-height: 150px;
  width: 100%;
  background: #fff;
  overflow-y: auto;
  z-index: 10;
}

.autocomplete-dropdown ul > li {
  padding: 7px 15px;
}
.autocomplete-dropdown ul > li:hover {
  cursor: pointer;
  background: #d9d9d9;
}

.kontirovki-clearable-select .select2-container .select2-selection--single .select2-selection__rendered, div
.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 20px;
}

.kontirovki-clearable-select .select2-selection__clear {
  font-size: 20px;
  right: 100%;
  width: 16px;
  text-align: center;
  line-height: 1.7;
}

.select2-selection__clear {
  position: absolute;
  right: 5px;
}

.product-title--js:hover {
  text-decoration: none;
}

.product-title--js span.product-name:hover {
  text-decoration: underline;
}

.mr-5 {margin-right: 5px;}
.ml-5 {margin-left: 5px;}

.single-icon-btn .fa-times-item:before {
  margin: 0;
}

.info_frame {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.content_header {
  font-style: normal;
  font-size: 23px;
  line-height: 34px;
  margin-top: 41px;
  margin-bottom: 32px;
}

.text_info {
  font-size: 14px;
  color: #4D4D4D;
  margin: 32px;
}

.main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.footer-buttons {
    display: flex;
    justify-content: space-between;
}

.footer-buttons .left-block,
.footer-buttons .right-block {
    display: flex;
    align-items: center;
}

.footer-buttons .left-block {
    justify-content: start;
}

.footer-buttons .right-block {
    justify-content: end;
}

.footer-buttons .form-group {
    margin: 0;
}

.footer-buttons .form-group .checkbox span {
    white-space: nowrap;
}

.footer-buttons .left-block > * + *,
.footer-buttons .right-block > * + * {
    margin-left: 20px;
}

.fancytree-node {
  &:not(.fancytree-selected, .fancytree-partsel) {
    .fancytree-checkbox {
      background-position: 0px -32px !important;
        &:hover {
          background-position: -16px -32px !important;
        }
    }
  }

  .fancytree-expander,
  .fancytree-checkbox,
  .fancytree-icon {
    width: 16px !important;
    height: 16px !important;
  }
}

.swiper-button-next, .swiper-button-prev {
  &:after {
    color: #5e5e5e;
  }
}

.ugmk-custom-icon {
  fill: none;
  max-width: 24px;
  height: 24px;
  width: 24px;
}

.ugmk-custom-icon--xl {
  max-width: 48px;
  width: 48px;
  height: 48px;
}

.ugmk-custom-icon.blue-icon {
  color: #1A32BA;
}

.ugmk-custom-icon.grey-icon {
  color: #7D7D7D;
}

.ugmk-custom-icon.ugmk-custom-icon--sm {
  width: 20px;
  height: 20px;
  max-width: 20px;
}

.ugmk-custom-icon.ugmk-custom-icon--xs {
  width: 16px;
  height: 16px;
  max-width: 16px;
}

.panel, .panel-body {
  border-radius: 16px !important;
}
.panel{
  padding-left: 20px;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.select2-dropdown, .daterangepicker.dropdown-menu {
  z-index: 1025 !important;
}


:root:has(.modal.fade.in) {
  .select2-dropdown, .daterangepicker.dropdown-menu {
    z-index: 1125 !important;
  }
}

.root-container {
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
}

.root-container-header {
  flex-direction: column;
  gap: 0;
}

.root-content-main {
  width:100%;

  @include media(">=1480px"){
    padding-left:80px;
  }

  @include media("<=1480px"){
    padding-left: 80px;
  }

  @include media("<=1340px"){
    padding-left: 0;
  }

}

@supports (scrollbar-gutter: stable) {
  html {
    scrollbar-gutter: stable;
  }
}
.cf__top-level.active + * .root-sidebar:hover {
  max-width: $sidemenu_width_minimized !important;
  flex-basis: $sidemenu_width_minimized !important;
  pointer-events: none;
}
.open-sidebar {
  max-width:265px !important;

}

.transform-arrow {
  transform: rotate(180deg);
}

