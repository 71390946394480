.pagination__container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0px;
	margin: 24px 0px 0px 0px;
}
.pagination__pages {
	margin: 0px 44px 0px 0px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0px;
	.page-link {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 20px;
		color: #000;
		border: 0px;
		background: transparent;
		box-shadow: none;
		margin: 0px;
		padding: 2px;
		min-width: 24px;
		min-height: 24px;
		text-align: center;
		border-radius: 6px !important;
		&:hover,
		&:active {
			color: #000;
			background: #e0deda;
			text-decoration: none;
		}
		&.page-active {
			color: #fff;
			background: #4D83D6;
			&:hover,
			&:active {
				color: #fff;
				background: #4D83D6;
			}
		}
		&_control {
			line-height: 16px;
			padding: 3px 2px 5px;
		}
	}
	.page-item {
		padding: 0px;
		margin: 0px 8px 0px 0px;
		&.disabled {
			.page-link {
				background: transparent;
				color: #a19f99;
			}
		}
		&_control {
			margin-right: 0px;
		}
	}
}

.pagination__limit {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	&-title {
		font-style: normal;
		font-weight: 400;
		font-size: 11px;
		line-height: 16px;
		color: #000;
		margin-right: 16px;
	}
	&-select {
		padding: 0px;
		margin: 0px;
		border: 2px solid #e0deda;
		border-radius: 6px;
		position: relative;
		overflow: hidden;
	}
	.page-item {
		padding: 0px;
		margin: 0px;
		border-left: 2px solid #e0deda;
		&:first-child {
			border-left: 0px;
		}
		&.active {
			.page-link {
				background: #e0deda;
				color: #706f67;
			}
		}
	}
	.page-link {
		border: 0px;
		box-shadow: none;
		background: transparent;
		font-style: normal;
		font-weight: 700;
		font-size: 11px;
		line-height: 16px;
		letter-spacing: 0.03em;
		color: #706f67;
		padding: 3px 2px 1px;
		min-width: 32px;
		text-align: center;
		&:hover,
		&:active {
			background: #e0deda;
			color: #706f67;
		}
	}
}
.pagination-grid {
	margin-top: 45px;
}
