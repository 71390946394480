.products-filter {
    &__forms {
        @include media('>=sm') {
            order: 1;
        }
    }

    &__actions {
        margin-top: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        .btn {
            margin-left: 15px;
        }
        @include media('>=sm') {
            margin-right: 30px;
            margin-top: 31px;
            float: right;
        }
    }

    .form-group {
        margin-bottom: 12px;
    }

    div.radio, div.checkbox {
        min-height: 15px;
        margin: 0px 23px 0px 0px;
        padding: 0px;
        input[type="radio"], input[type="checkbox"] {
            display: inline-block;
            margin: 0px 8px 0px 0px;
        }
        label {
            margin-bottom: 0px;
        }
    }

    &__form-title  {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #4B4B4B;
        padding: 0px;
        margin-bottom: 16px;
    }

    &__price-fields {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    &__price-from,
    &__price-to {
        flex: 0 0 50%;
        max-width: 50%;
        @include media('>=sm') {
            max-width: 155px;
        }
    }
    &__price-from {
        padding-right: 5px;
    }
    &__price-to {
        padding-left: 5px;
    }

    &__currency {
        flex: 0 0 50%;
        max-width: 83px;
        @include media('>=sm') {
            max-width: 93px;
            padding-left: 10px;
        }
    }

    &__price-checkboxes {
        margin-bottom: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__supplier,
    &__warehouse-form {
        @include media('>=sm') {
            max-width: 403px;
        }
    }
}
