.supplier-card {
  &__navigation {
    margin-bottom: 24px;
    .breadcrumb {
      padding-left: 0;
      padding-bottom: 24px;
    }
    .limit-import-back {
      margin: 0;
      padding: 12px 20px;
      width: fit-content;
    }

    .bi {
      margin-left: 0 !important;
    }
  }

  &__content {
    display: flex;
    gap: 24px;

    @media (max-width: 500px) {
      flex-direction: column;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    color: rgba(25, 25, 25, 1);
    line-height: 1.2;
  }

  &__image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    border: 1px solid rgba(200, 200, 200, 1);
    border-radius: 16px;
    @media (max-width: 500px) {
      align-self: center;
    }
  }

  &__date {
    font-size: 13px;
    font-weight: 400;
    color: rgba(125, 125, 125, 1);
  }

  &__description {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
    color: rgba(75, 75, 75, 1);
  }

  &__tabs {
    margin: 24px 0;
  }

  &__tab-links {
    height: 41px;
    display: flex;
    gap: 16px;

    a {
      font-size: 14px;
      font-weight: 400;
      color: rgba(125, 125, 125, 1) !important;
      padding-bottom: 20px;
      transition: border-bottom 0.2s ease-in-out, color 0.2s ease-in-out;
      border-bottom: 2px solid transparent;

      &.active {
        color: rgba(0, 0, 0, 1) !important;
        border-bottom: 2px solid currentColor;
      }
    }
  }

  &__tab-content {
    display: none;

    &.active {
      display: block;
    }
  }

  &__tab-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    color: rgba(25, 25, 25, 1);
    margin: 24px 0 !important;
  }

  &__address-key {
    font-size: 15px;
    font-weight: 400;
    color: rgba(125, 125, 125, 1);
    line-height: 1.4;
    width: 83px !important;
  }

  &__address-value {
    font-size: 15px;
    font-weight: 400;
    color: rgba(75, 75, 75, 1);
    line-height: 1.4;
    a {
      color: rgba(75, 75, 75, 1) !important;
    }
  }

  &__panel {
    border-radius: 8px !important;
    a {
      color: rgba(50, 50, 50, 1) !important;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.2;
      &:hover {
        color: rgba(26, 50, 186, 1) !important;
      }
    }
  }

  &__panel-heading {
    border: none !important;
    background-color: rgba(245, 245, 245, 1) !important;
    width: 100%;
    &-arrows-icon {
      transition: transform 0.3s ease-in-out;
    }
    &.collapsed > &-arrows-icon {
      transform: rotate(-180deg);
    }

    &--child {
      padding-right: 0px !important;
    }
  }

  &__panel-body {
    background-color: rgba(245, 245, 245, 1) !important;
    border-radius: 0 0 8px 8px !important;
    padding-right: 21px !important;
    &--child {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
    a {
      color: rgba(75, 75, 75, 1) !important;
      font-weight: 400;
    }
  }

  &__panel-body-tags {
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 1);
    padding: 12px;
    a {
      font-size: 14px;
    }
  }
}
