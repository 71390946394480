.tooltip {
    position: absolute;
    z-index: 999;
    display: block;
    visibility: visible;
    font-size: 12px;
    line-height: 1.4;
    opacity: 0;
    filter: alpha(opacity=0);

    &.in {
        opacity: 1;
        filter: alpha(opacity=100);
    }

    &.top {
        margin-top: -3px;
        padding: 5px 0;

        .tooltip-arrow {
            bottom: 0;
            left: 50%;
            margin-left: -5px;
            border-width: 5px 5px 0;
            border-top-color: #000;
        }

        &-left .tooltip-arrow {
            bottom: 0;
            left: 5px;
            border-width: 5px 5px 0;
            border-top-color: #000;
        }

        &-right .tooltip-arrow {
            bottom: 0;
            right: 5px;
            border-width: 5px 5px 0;
            border-top-color: #000;
        }
    }

    &.right {
        margin-left: 3px;
        padding: 0 5px;

        .tooltip-arrow {
            top: 50%;
            left: 0;
            margin-top: -5px;
            border-width: 5px 5px 5px 0;
            border-right-color: #000;
        }
    }

    &.bottom {
        margin-top: 3px;
        padding: 5px 0;

        .tooltip-arrow {
            top: 0;
            left: 50%;
            margin-left: -5px;
            border-width: 0 5px 5px;
            border-bottom-color: #000;
        }

        &-left .tooltip-arrow {
            top: 0;
            left: 5px;
            border-width: 0 5px 5px;
            border-bottom-color: #000;
        }

        &-right .tooltip-arrow {
            top: 0;
            right: 5px;
            border-width: 0 5px 5px;
            border-bottom-color: #000;
        }
    }

    &.left {
        margin-left: -3px;
        padding: 0 5px;

        .tooltip-arrow {
            top: 50%;
            right: 0;
            margin-top: -5px;
            border-width: 5px 0 5px 5px;
            border-left-color: #000;
        }
    }

    &-inner {
        max-width: 200px;
        padding: 3px 8px;
        color: #fff;
        text-align: center;
        text-decoration: none;
        background-color: #000;
        border-radius: 1px;

        &-md {
            max-width: 400px;
            border-radius: 5px;
        }
    }

    &-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
}

.tooltip.tooltip-white .tooltip-inner {
    background: #ffffff !important;
    border:1px solid #E5E5E5;
    color: #000000;
    padding: 15px;
    border-radius: 16px;
    text-align: left;
    font-size: 13px;
}

.tooltip.tooltip-black .tooltip-inner {
    background: #191919 !important;
    border:1px solid #191919;
    color: #FFFFFF;
    padding: 15px;
    border-radius: 5px;
    text-align: left;
    font-size: 13px;
}
