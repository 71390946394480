
.category-link-styles {
  display: block;
  margin-bottom: 8px;

  &:hover {
    text-decoration: none;

    .category-text {
      border-bottom-color: #4f8edc;
    }
  }
}

.category-text {
    border-bottom: 2px solid #E5E5E5;
    color: #4B4B4B;
    padding-bottom: 5px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
}
.root_category_widget {
  display: flex;
  flex-flow: row wrap;
}

.root_category {
  margin-bottom: 40px;
  min-width: 150px;
  max-width: 25%;
  width: 100%;

  &__top_link {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg.bi {
      width: 30px;
      height: 30px;
    }

    .img-responsive {
      height: auto;
    }
  }
}
